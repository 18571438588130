/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'communityCode',
    label: 'Inserzioni della Community',
    type: 'CommunityCodeFilter',
    group: 'primary',
    queryParamNames: ['hasCommunityCode'],
    config: {},
  },
  {
    id: 'dates',
    label: 'Data',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Prezzo',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Parola chiave',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'listingType',
    label: 'Tipologia',
    type: 'ListingTypeFilter',
    group: 'secondary',
    queryParamNames: ['pub_type'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all', label: 'Tutte' },
        { key: 'product', label: 'Prodotti' },
        { key: 'service', label: 'Servizi' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Categorie',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // as we have two types of listings, we have two types of categories 1. product 2. service
      product: {
        // "key" is the option you see in Flex Console.
        // "label" is set here for the UI only.
        // Note: label is not added through the translation files
        // to make filter customizations a bit easier.
        options: [
          { key: 'constructionTools&equipment', label: 'Edilizia & Attrezzatura' },
          { key: 'home&garden', label: 'Casa & Giardinaggio' },
          { key: 'electronics', label: 'Elettronica' },
          { key: 'party', label: 'Feste & Eventi' },
          { key: 'vehicles', label: 'Veicoli & Trasporti' },
          { key: 'sports&leisure', label: 'Sport & Tempo libero' },
          { key: 'creativity', label: 'Creatività & Arte' },
          { key: 'baby', label: 'Bambini' },
          { key: 'spaces', label: 'Spazi' },
          { key: 'outdoorActivity', label: 'Attività all\'aperto' },
          { key: 'clothesFashion', label: 'Moda & Abbigliamento' },
          { key: 'education', label: 'Educazione & Formazione' },
          { key: 'cameras', label: 'Fotografia' },
          { key: 'sanitaryServices', label: 'Assistenza Sanitaria' },
          { key: 'other', label: 'Altro' },
        ],
      },
      service: {
        // "key" is the option you see in Flex Console.
        // "label" is set here for the UI only.
        // Note: label is not added through the translation files
        // to make filter customizations a bit easier.
        options: [
          { key: 'sanitaryServices', label: 'Assistenza Sanitaria' },
          { key: 'home&garden', label: 'Casa & Giardinaggio' },
          { key: 'techyServices', label: 'Servizi Tech' },
          { key: 'party', label: 'Feste & Eventi' },
          { key: 'vehicles', label: 'Veicoli & Trasporti' },
          { key: 'sports&leisure', label: 'Sport & Tempo libero' },
          { key: 'baby', label: 'Bambini' },
          { key: 'education', label: 'Educazione & Formazione' },
          { key: 'creativity', label: 'Creatività & Arte' },
          { key: 'other', label: 'Altro' }
        ],
      }
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Le più recenti' },
    { key: '-createdAt', label: 'Le più vecchie' },
    { key: '-price', label: 'Prezzo più basso' },
    { key: 'price', label: 'Prezzo più alto' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Rilevanza', longLabel: 'Rilevanza (Ricerca per parola chiave)' },
  ],
};

// Occupations need for user profile settings page
export const occupations = [
  {
    key: 'student',
    label: 'Studente'
  },
  {
    key: 'teacher',
    label: 'Insegnante'
  },
  {
    key: 'freelance',
    label: 'Freelance'
  },
  {
    key: 'entrepreneur',
    label: 'Imprenditore'
  },
  {
    key: 'practitioner',
    label: 'Professionista'
  },
  {
    key: 'craftsman',
    label: 'Artigiano'
  },
  {
    key: 'technician',
    label: 'Tecnico'
  },
  {
    key: 'artist',
    label: 'Artista'
  },
  {
    key: 'healthcare_worker',
    label: 'Operatore sanitario'
  },
  {
    key: 'employee',
    label: 'Dipendente (pubblico o privato)'
  },
  {
    key: 'other',
    label: 'Altro (specificare)'
  },
];

// Hobbies need for user profile settings page
export const hobbies = [
  {
    value: 'lettura',
    label: 'Lettura'
  },
  {
    value: 'cinema',
    label: 'Cinema'
  },
  {
    value: 'musica',
    label: 'Musica'
  },
  {
    value: 'viaggi',
    label: 'Viaggi'
  },
  {
    value: 'cucina',
    label: 'Cucina'
  },
  {
    value: 'arte_e_cultura',
    label: 'Arte e cultura'
  },
  {
    value: 'sport',
    label: 'Sport'
  },
  {
    value: 'tecnologia',
    label: 'Tecnologia'
  },
  {
    value: 'fotografia',
    label: 'Fotografia'
  },
  {
    value: 'giardinaggio',
    label: 'Giardinaggio'
  },
  {
    value: 'animali_domestici',
    label: 'Animali domestici'
  },
  {
    value: 'giochi_di_ruolo',
    label: 'Giochi di ruolo'
  },
  {
    value: 'videogiochi',
    label: 'Videogiochi'
  },
  {
    value: 'moda_e_design',
    label: 'Moda e design'
  },
  {
    value: 'ecologia_e_sostenibilità',
    label: 'Ecologia e sostenibilità'
  },
  {
    value: 'volontariato_e_beneficenza',
    label: 'Volontariato e beneficenza'
  },
  {
    value: 'lingue_straniere',
    label: 'Lingue straniere'
  },
  {
    value: 'yoga_e_meditazione',
    label: 'Yoga e meditazione'
  },
  {
    value: 'attività_all_aria_aperta',
    label: 'Attività all\'aria aperta'
  },
  {
    value: 'scrittura_e_giornalismo',
    label: 'Scrittura e giornalismo'
  },
];

// Listing discount choices
export const discountChoices = [
  {
    key: 5,
    label: '5%'
  },
  {
    key: 10,
    label: '10%'
  },
  {
    key: 20,
    label: '20%'
  },
  {
    key: 30,
    label: '30%'
  },
]
