import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { ModalInMobile, Button, ListingDiscount, PrimaryButton } from '../../components';
import { BookingDatesForm } from '../../forms';

import css from './BookingPanel.module.css';

const { Money } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const SEVEN_DAYS_PLUS = 'sevenDaysPlus'
const THIRTY_DAYS_PLUS = 'thirtyDaysPlus'

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    handleRequestForBuy,
    isServiceListing,
    onContactUser,
  } = props;

  const price = listing.attributes.price;
  const publicData = listing.attributes.publicData;
  // sale price
  const salePrice = publicData && publicData.salePrice ? new Money(publicData.salePrice[0], publicData.salePrice[1]) : null
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const { formattedPrice: formattedSalePrice } = priceData(salePrice, intl);
  const isBook = !!parse(location.search).book;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0;

  // has discount
  const discounts = publicData && publicData.discounts || {};
  const hasDiscount = Object.keys(discounts).length > 0;
  const hasMultipleDiscounts = Object.keys(discounts).length > 1;
  const percentageOf7Days = discounts[SEVEN_DAYS_PLUS]?.percent || null
  const percentageOf30Days = discounts[THIRTY_DAYS_PLUS]?.percent || null

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isServiceListing
    ? 'BookingPanel.perGig'
    : 'BookingPanel.perDay'
  const sevenDaysPlusDiscountTitle = intl.formatMessage({id: 'BookingPanel.sevenDaysPlusDiscountTitle'});
  const thirtyDaysPlusDiscountTitle = intl.formatMessage({id: 'BookingPanel.thirtyDaysPlusDiscountTitle'});


  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
        </div>

        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          {subTitleText && !isOutOfStock ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
        </div>
        {showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            salePrice={salePrice}
            formattedSalePrice={formattedSalePrice}
            currentStock={currentStock}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            handleRequestForBuy={handleRequestForBuy}
            isServiceListing={isServiceListing}
            onContactUser={onContactUser}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        { hasDiscount ? (
          <div className={css.desktopDiscountWrapper}>
            { hasDiscount && percentageOf7Days ? (
              <ListingDiscount
                title={sevenDaysPlusDiscountTitle}
                price={price}
                percentage={percentageOf7Days}
                priceData={priceData}
                intl={intl}
                unitTranslationKey={unitTranslationKey}
                hasMultipleDiscounts={hasMultipleDiscounts}
              />
            ) : null }
            { hasDiscount && percentageOf30Days ? (
              <ListingDiscount
                title={thirtyDaysPlusDiscountTitle}
                price={price}
                percentage={percentageOf30Days}
                priceData={priceData}
                intl={intl}
                unitTranslationKey={unitTranslationKey}
              />
            ) : null }
          </div>
        ) : null }
        <div style={{display: 'flex'}}>
          <div className={css.priceContainer}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          </div>

          {showBookingDatesForm ? (
            <Button
              rootClassName={css.bookButton}
              onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
              disabled={isOutOfStock}
            >
              {isOutOfStock ? (
                <FormattedMessage id="BookingPanel.ctaButtonMessageNoStock" />
              ) : (
                <FormattedMessage id="BookingPanel.ctaButtonMessage" />
              )}
            </Button>
          ) : isClosed ? (
            <div className={css.closedListingButton}>
              <FormattedMessage id="BookingPanel.closedListingButtonText" />
            </div>
          ) : null}
        </div>
        {salePrice !== null && !isOutOfStock ? (
          <div className={css.buySalepriceWrapper}>
            <span className={css.ordivder} style={{margin: '2px 0'}}>
              <FormattedMessage id="BookingDatesForm.dividerOr" />
            </span>
            <div className={css.buyBtnWrapper}>
              <PrimaryButton type="button" onClick={handleRequestForBuy}>
                <FormattedMessage
                  id="BookingDatesForm.ctaButtonBy"
                  values={{ formattedSalePrice }}
                />
              </PrimaryButton>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
  isServiceListing: false,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  isServiceListing: bool,
  onContactUser: func,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
