import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import people1 from './people1.jpeg';
import people2 from './people2.jpeg';

import css from './SectionPeopleWhoLikes.module.css';

class TryEaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return (
      <div className={css.imageMargin}>
        <img alt={alt} {...rest} />
      </div>
    )
  }
}
const LazyImage = lazyLoadWithDimensions(TryEaselyImage);

const Image = ({src, title}) => (
  <div className={css.imageWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.tryEaselyImage} />
    </div>
  </div>
);

const SectionPeopleWhoLikes = () => {
  return (
    <article className={css.root}>
      <h1 className={css.mobileTitle}>
        <FormattedMessage id="SustainabilityPage.SectionPeopleWhoLikesTtitle" />
      </h1>
      <div className={css.feedbackBox}>
        <Image src={people2} alt="NILL SMITH"/>
        <p className={css.feedbackText}>
          "<FormattedMessage id="SustainabilityPage.SectionPeopleWhoLikesFeedbackOne" />"
        </p>
        <h3 className={css.name}>NILL SMITH</h3>
        <h4 className={css.tag}>Financial Director</h4>
      </div>
      <div>
        <h1 className={css.title}>
          <FormattedMessage id="SustainabilityPage.SectionPeopleWhoLikesTtitle" />
        </h1>
        <div className={css.feedbackBox}>
          <Image src={people1} alt="MERRY KINNLY"/>
          <p className={css.feedbackText}>
            "<FormattedMessage id="SustainabilityPage.SectionPeopleWhoLikesFeedbackTwo" />"
          </p>
          <h3 className={css.name}>MERRY KINNLY</h3>
          <h4 className={css.tag}>ANGI</h4>
        </div>
      </div>
    </article>
  )
}

export default SectionPeopleWhoLikes;
