import React from 'react'

export default function GlobeIcon() {
  return (
    <svg
      width="44px"
      height="44px"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            d="M473.885,212.673c-7.358-3.803-16.408-0.918-20.211,6.441l-17.455,33.783l-16.803-33.605    C416.876,214.21,411.682,211,406,211s-10.876,3.21-13.416,8.292L376,252.459l-16.584-33.167    c-2.688-5.379-8.318-8.626-14.334-8.264l-0.908,0.056c-6.684,0.41-12.072,5.138-13.626,11.303    c-1.022-4.108-3.757-7.763-7.84-9.804c-7.407-3.703-16.419-0.702-20.124,6.708L286,252.459l-16.583-33.167    C266.875,214.21,261.682,211,256,211s-10.875,3.21-13.417,8.292L226,252.459l-16.583-33.167    c-3.705-7.409-12.716-10.413-20.125-6.708c-4.083,2.041-6.818,5.696-7.84,9.803c-1.553-6.165-6.942-10.894-13.626-11.304    l-0.908-0.056c-6.004-0.37-11.645,2.885-14.334,8.264L136,252.459l-16.583-33.167c-5.461-10.895-21.253-11.161-26.833,0    l-16.803,33.606l-17.455-33.782c-3.802-7.36-12.851-10.243-20.211-6.441c-7.36,3.803-10.244,12.852-6.441,20.212l31,60    c5.627,10.892,21.255,10.798,26.743-0.177L106,259.541l16.584,33.167c5.524,11.047,21.322,11.022,26.833,0l27.949-55.9    c1.966-1.911,3.411-4.364,4.097-7.135c0.261,1.029,0.627,2.046,1.121,3.035l30,60c5.524,11.047,21.322,11.022,26.833,0    L256,259.541l16.583,33.167c5.524,11.047,21.323,11.023,26.833,0l30-60.001c0.494-0.988,0.86-2.005,1.121-3.034    c0.686,2.772,2.131,5.226,4.098,7.137l27.949,55.898c5.522,11.047,21.323,11.022,26.832,0L406,259.541l16.584,33.167    c5.481,10.965,21.11,11.078,26.742,0.177l31-60.001C484.129,225.524,481.245,216.475,473.885,212.673z"
          ></path>

        </g>
      </g>
      <g>
        <g>
          <path
            d="M493.032,159.329C454.912,66.291,362.617,0,256,0C149.356,0,57.078,66.314,18.969,159.328C7.501,167.492,0,180.882,0,196    v120c0,15.118,7.501,28.508,18.969,36.672C57.087,445.707,149.381,512,256,512c106.644,0,198.921-66.314,237.032-159.329    C504.499,344.507,512,331.117,512,316V196C512,180.883,504.499,167.493,493.032,159.329z M456.045,151h-76.687    c-9.533-40.845-24.413-76.012-43.344-102.258c-1.323-1.833-2.662-3.611-4.015-5.345C384.608,62.44,429.425,100.687,456.045,151z     M271,32.512C312.045,46.293,337.711,108.43,348.486,151H271V32.512z M241,32.512V151h-77.487    c8.616-34.039,21.198-63.073,36.804-84.708C209.693,53.294,223.639,38.341,241,32.512z M180.001,43.397    c-1.353,1.734-2.692,3.511-4.015,5.345C157.055,74.988,142.174,110.155,132.642,151H55.955    C82.575,100.687,127.391,62.44,180.001,43.397z M55.955,361h76.687c8.73,37.405,23.727,77.315,47.359,107.603    C127.391,449.56,82.575,411.313,55.955,361z M241,479.488C199.955,465.707,174.288,403.57,163.513,361H241V479.488z M271,479.488    V360.8h77.487C337.695,403.433,311.958,465.736,271,479.488z M331.999,468.603c23.629-30.284,38.628-70.39,47.359-107.803h76.687    C429.425,411.113,384.609,449.56,331.999,468.603z M482,316c0,8.271-6.729,15-15,15c-9.517,0-411.12,0-422,0    c-8.271,0-15-6.729-15-15V196c0-8.271,6.729-15,15-15c11.435,0,411.732,0,422,0c8.271,0,15,6.729,15,15V316z"
          ></path>
        </g>
      </g>
  </svg>
  )
}
