import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import makeImpactImage from './make-impact.png';
import ScrollAnimation from 'react-animate-on-scroll';


import css from './SectionMakeImpact.module.css';
import CheckmarkIcon from './CheckmarkIcon';
import NamedLink from '../NamedLink/NamedLink';


const Bubbles = () => (
  <>
    <ScrollAnimation
      animateIn={css.bubbleIn1}
      animateOut={css.bubbleOut1}
    />
    <ScrollAnimation
      animateIn={css.bubbleIn2}
      animateOut={css.bubbleOut2}
    />
    <ScrollAnimation
      offset={100}
      animateIn={css.bubbleIn3}
      animateOut={css.bubbleOut3}
    />
  </>
)
class EaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(EaselyImage);

const Image = ({src, title}) => (
  <div className={css.imageWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.easelyImage} />
    </div>
    <Bubbles />
  </div>
);


const SectionMakeImpact = () => {
  return (
    <article className={css.root}>
      <div>
        <h1 className={css.sectionMakeImpactTitle}>
          <FormattedMessage id="SectionMakeImpact.makeImpactTitleHead" />
          <span className={css.highlightedByColor}>
            {" "}<FormattedMessage id="SectionMakeImpact.makeImpactTitleHeadYou" />{" "}
          </span>
          <FormattedMessage id="SectionMakeImpact.makeImpactTitleHeadTo" />
          <span className={css.highlightedByColor}>
            {" "}<FormattedMessage id="SectionMakeImpact.makeImpactTitleMiddle" />{" "}
          </span>
          <FormattedMessage id="SectionMakeImpact.makeImpactTitleTail" />
        </h1>
        <Image src={makeImpactImage} title="Make an impact" />
        <ul className={css.listWrapper}>
          <li className={css.listItem}>
            <CheckmarkIcon />
            <p className={css.itemInfo}>
              <FormattedMessage id="SectionMakeImpact.makeImpactSubtitleOneHead" />
              <span className={css.highlightedByBold}>
                {" "}<FormattedMessage id="SectionMakeImpact.makeImpactSubtitleOneMiddle" />{" "}
              </span>
              <FormattedMessage id="SectionMakeImpact.makeImpactSubtitleOneTail" />
              </p>
          </li>
          <li className={css.listItem}>
            <CheckmarkIcon />
            <p className={css.itemInfo}>
              <FormattedMessage id="SectionMakeImpact.makeImpactSubtitleTwoHead" />
              <span className={css.highlightedByBold}>
                {" "}<FormattedMessage id="SectionMakeImpact.makeImpactSubtitleTwoHeadDonate" />{" "}
              </span>
              <FormattedMessage id="SectionMakeImpact.makeImpactSubtitleTwoMiddle" />
              <span className={css.highlightedByBold}>
                {" "}<FormattedMessage id="SectionMakeImpact.makeImpactSubtitleTwoTail" />{" "}
              </span>
            </p>
          </li>
          <li className={css.listItem}>
            <CheckmarkIcon />
            <p className={css.itemInfo}>
              <FormattedMessage id="SectionMakeImpact.makeImpactSubtitleThree" />
            </p>
          </li>
        </ul>
        <NamedLink name="SustainabilityPage" className={css.link}>
          <button
            type="button"
            className={css.discoverProjectButton}
          >
            <FormattedMessage id="SectionMakeImpact.discoverProjectButton" />
          </button>
        </NamedLink>
      </div>
      <div className={css.makeImpactImage}>
        <Bubbles />
      </div>
    </article>
  )
}

export default SectionMakeImpact;
