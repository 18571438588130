import React from 'react';
import { FieldSelect } from '../../../components';
import { intlShape } from '../../../util/reactIntl';

import css from './AgeSelectionField.module.css';

const ages = [
  '18 - 29',
  '30 - 39',
  '40 - 49',
  '50 - 59',
  '60 - 69',
  '70+'
];

const AgeSelectionField = ({intl}) => {
  const ageLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.ageLabel',
  });
  const agePlaceholder = intl.formatMessage({
    id: 'ProfileSettingsForm.agePlaceholder',
  });

  return ages ? (
    <FieldSelect
      className={css.ageInput}
      name='age'
      id='age'
      label={ageLabel}
    >
      <option disabled value="">
        {agePlaceholder}
      </option>
      {ages.map((age, index) => (
        <option key={index} value={age}>
          {age}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

AgeSelectionField.prototype = {
  intl: intlShape.isRequired
}

export default AgeSelectionField;
