import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { IconSpinner, ValidationError } from '../../../components';
import easely from './easely.png'


import css from './VideoField.module.css';
import RemoveImageButton from '../../../components/AddImages/RemoveImageButton';

const ACCEPT_IMAGES = 'video/*';
const MAX_SIZE_OF_UPLOAD_VIDEO = 100;
/**
 * A helper function that generates img src from a File object
 * @param {string} file
 * @returns {Promise} Promise
 */

async function getUrlFromImageFile(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const VideoField = ({
  intl,
  form,
  video,
  onVideoUpload,
  onRemoveVideo,
  videoUploading,
  setVideoUploading,
  images,
}) => {
  const [ playing, setPlaying] = useState(false);
  const [ sizeError, setSizeError] = useState(null);
  const [thumbnail, setThumbnail] = useState(easely);
  const videoSrc = video.length > 0 && video[0].location;
  const videoKey = video.length > 0 && video[0].key;

  const hasImageFile = images.length > 0 && images[0].file;
  const hasImages = images.length > 0 && images[0].attributes;
  const firstImage = hasImages ? images[0] : null;

  // video thumbnail
  const imageVariants = firstImage && firstImage.attributes.variants;
  const has2xVariant = imageVariants && imageVariants['landscape-crop2x'] !== undefined;
  const videoThumbnail = has2xVariant ? imageVariants['landscape-crop2x'].url : null;

  useEffect(() => {
    (async () => {
      let url;
      if(hasImageFile){
        url = await getUrlFromImageFile(images[0].file);
      } else if(videoThumbnail){
        url = videoThumbnail;
      } else {
        url = easely
      }
      setThumbnail(url)
    })()
  }, [images]);

  const onVideoUploadingHandler = async (file) => {
    setVideoUploading(true);
    try {
      if(file){
        const type = file.type;
        const ext = type.split('/')[1];
        // if video size  > 100 don't call upload fnc
        const size = parseFloat(((file.size / 1024) / 1024).toFixed(2));
        if(size > MAX_SIZE_OF_UPLOAD_VIDEO){
          setSizeError('Video size can not be greater 100MBs');
          return;
        }

        // this will override the file name
        let fileToUpload;
        const formdata = new FormData();
        formdata.append('file', file, `easely-aws-video-${Date.now()}.${ext}`);
        for(let entry of formdata.entries()) {
          fileToUpload = entry[1];
        }

        if(fileToUpload){
          const res = await onVideoUpload(fileToUpload);
          if(res){
            setVideoUploading(false);
          }
        }
      }
    } catch (error) {
      setVideoUploading(false);
    }
  }

  const hanldeOnRemoveVideo = async (e) => {
    try {
      e.stopPropagation();
      await onRemoveVideo(videoKey);
    } catch (error) {
      // Handle error
    }
  }

  const videoMessage = intl.formatMessage({ id: 'EditListing.video' });
  const videoUploadingLabel = intl.formatMessage({ id: 'EditListing.videoUploading' });
  const videoPlaceholderMessage = intl.formatMessage({
    id: 'EditListing.videoPlaceholder',
  });

  const uploadInputCalsses = classNames(css.uploadVideo, {
    // [css.inputSuccess]: valid,
    // [css.inputError]: hasError,
    [css.inputDisabled]: videoUploading,
  });

  return (
    <div className={css.root}>
      {video.length > 0 ? (
        <div>
          <h4 className={css.chooseImage}>{videoMessage}</h4>
          <div className={css.playerWrapper}>
            <ReactPlayer
              className={css.player}
              playing={playing}
              onClickPreview={(e) => {
                e.preventDefault();
                setPlaying(!playing)
              }}
              url={videoSrc}
              light={thumbnail}
              width="100%"
              height="100%"
              controls={true}
            />
            <RemoveImageButton onClick={hanldeOnRemoveVideo}/>
          </div>
        </div>
      ): (
        <>
          <Field
            id="uploadVideo"
            name="uploadVideo"
            accept={ACCEPT_IMAGES}
            form={null}
            label={videoPlaceholderMessage}
            type="file"
            disabled={videoUploading}
          >
            {fieldprops => {
              const { accept, input, label, disabled: fieldDisabled } = fieldprops;
              const { name, type } = input;
              const onChange = e => {
                const file = e.target.files[0];
                form.change(`uploadVideo`, file);
                form.blur(`uploadVideo`);
                onVideoUploadingHandler(file);
              };
              const inputProps = { accept, id: name, name, onChange, type };
              return (
                <div className={css.uploadVideoWrapper}>
                  <h4 className={css.chooseImage}>{videoMessage}</h4>
                  <div className={css.aspectRatioWrapper}>
                    {fieldDisabled ? null : (
                      <input {...inputProps} className={css.uploadVideoInput} />
                    )}
                    <label htmlFor={name} className={uploadInputCalsses}>
                      {videoUploading ? (
                        <span>
                          <IconSpinner /> {videoUploadingLabel}
                        </span>
                        ) : label}
                    </label>
                  </div>
                </div>
              );
            }}
          </Field>

          <Field
            component={props => {
              const { input, meta } = props;
              return (
                <div className={css.imageRequiredWrapper}>
                  <input {...input} />
                  <ValidationError fieldMeta={meta} />
                </div>
              );
            }}
            name="video"
            type="hidden"
          />
        </>
      )}
    </div>
  )
}
export default VideoField
