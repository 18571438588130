import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field } from 'react-final-form';
import { FormattedMessage } from '../../../util/reactIntl';

import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { AddImages, ValidationError } from '../../../components';
import { isUploadImageOverLimitError } from '../../../util/errors';

import css from './AddImagesField.module.css';

const ACCEPT_IMAGES = 'image/*';

const AddImagesField = ({
  images,
  intl,
  form,
  onImageUpload,
  onRemoveImage,
  isImageAdded,
  setIsImageAdded,
  fetchErrors,
  imageUploadRequested,
  setImageUploadRequested,
}) => {
  const onImageUploadHandler = (file) => {
    if (file) {
      setIsImageAdded(false)
      setImageUploadRequested(true)
      onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          setImageUploadRequested(false)
        })
        .catch(() => {
          setImageUploadRequested(false)
        });
    }
  }

  const asterisk = images && images.length > 0 ? null : (
    <span className={css.asterisk}>*</span>
  );

  const chooseImageText = (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id="EditListingPhotosForm.chooseImage" values={{asterisk}} />
      </span>
      <span className={css.imageTypes}>
        <FormattedMessage id="EditListingPhotosForm.imageTypes" />
      </span>
    </span>
  );

  const imageRequiredMessage = intl.formatMessage({
    id: 'EditListingPhotosForm.imageRequired',
  });

  const { uploadImageError } = fetchErrors || {};
  const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

  let uploadImageFailed = null;

  if (uploadOverLimit) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
      </p>
    );
  } else if (uploadImageError) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
      </p>
    );
  }

  return (
    <div className={css.root}>
      <AddImages
        className={css.imagesField}
        images={images}
        thumbnailClassName={css.thumbnail}
        savedImageAltText={intl.formatMessage({
          id: 'EditListingPhotosForm.savedImageAltText',
        })}
        onRemoveImage={onRemoveImage}
      >
        <Field
          id="addImage"
          name="addImage"
          accept={ACCEPT_IMAGES}
          form={null}
          label={chooseImageText}
          type="file"
          disabled={imageUploadRequested}
        >
          {fieldprops => {
            const { accept, input, label, disabled: fieldDisabled } = fieldprops;
            const { name, type } = input;
            const onChange = e => {
              const file = e.target.files[0];
              form.change(`addImage`, file);
              form.blur(`addImage`);
              onImageUploadHandler(file);
            };
            const inputProps = { accept, id: name, name, onChange, type };
            return (
              <div className={css.addImageWrapper}>
                <div className={css.aspectRatioWrapper}>
                  {fieldDisabled ? null : (
                    <input {...inputProps} className={css.addImageInput} />
                  )}
                  <label htmlFor={name} className={css.addImage}>
                    {label}
                  </label>
                </div>
              </div>
            );
          }}
        </Field>

        <Field
          component={props => {
            const { input, meta } = props;
            return (
              <div className={css.imageRequiredWrapper}>
                <input {...input} />
                <ValidationError fieldMeta={meta} />
              </div>
            );
          }}
          name="images"
          type="hidden"
        />
      </AddImages>
      {uploadImageFailed}

      {isImageAdded && (
        <p className={css.errorMsg}>
          {imageRequiredMessage}
        </p>
      )}

      <p className={css.tip}>
        <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
      </p>
    </div>
  )
}

export default AddImagesField
