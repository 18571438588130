import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import ScrollAnimation from 'react-animate-on-scroll';

import css from './SectionTheWayWeImpact.module.css';
import GlobeIcon from './icons/GlobeIcon';
import BoxIcon from './icons/BoxIcon';


class TryEaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return (
      <div className={css.imageMargin}>
        <img alt={alt} {...rest} />
      </div>
    )
  }
}
const LazyImage = lazyLoadWithDimensions(TryEaselyImage);

const Image = ({src, title}) => (
  <div className={css.imageWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.tryEaselyImage} />
    </div>
  </div>
);

const SectionTheWayWeImpact = () => {
  return (
    <article className={css.root}>
      <h1 className={css.title}>
        <FormattedMessage id="SustainabilityPage.SectionTheWayWeImpactTitleHead" />
        <span className={css.highlightedByColor}>
          {" "}<FormattedMessage id="SustainabilityPage.SectionTheWayWeImpactTitleTail" />
        </span>
      </h1>
      <div className={css.boxWrapper}>
        <div className={css.box}>
          <span className={css.iconWrapper}>
            <BoxIcon />
          </span>
          <h3 className={css.subtitle}>
            <FormattedMessage id="SustainabilityPage.SectionTheWayWeImpactDonationBoxTitle" />
          </h3>
          <p className={css.description}>
            <FormattedMessage id="SustainabilityPage.SectionTheWayWeImpactDonationBoxDescription" />
          </p>
        </div>
        <div className={css.box}>
          <span className={css.iconWrapper}>
            <GlobeIcon />
          </span>
          <h3 className={css.subtitle}>
            <FormattedMessage id="SustainabilityPage.SectionTheWayWeImpactGreenBoxTitle" />
          </h3>
          <p className={css.description}>
            <FormattedMessage id="SustainabilityPage.SectionTheWayWeImpactGreenBoxDescription" />
          </p>
        </div>
      </div>
    </article>
  )
}

export default SectionTheWayWeImpact;
