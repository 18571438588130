import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div className={css.lastUpdated}>Ultima modifica: 9 Marzo, 2023</div>

      <h2>1. Disposizioni generali </h2>
      <div>
        La presente Piattaforma peer-to-peer è gestita da Easely Srl (di seguito la “Società”) con sede legale in Via Bernardo Quaranta 45, 20139 Milano (Mi), e si intende regolata dalle presenti Condizioni Generali di Utilizzo del Servizio.<br/><br/>
        Ogni volta che si accede e si utilizza la Piattaforma, si accetta di rispettare tali Condizioni Generali.<br/><br/>
        La Società si riserva il diritto di modificare in qualsiasi momento le presenti Condizioni Generali di Utilizzo del Servizio. Eventuali modifiche saranno efficaci immediatamente dopo la pubblicazione delle stesse sulla Piattaforma. Si consiglia pertanto di consultare regolarmente le Condizioni Generali per essere sempre aggiornati su eventuali modifiche.<br/><br/>
        L'accesso alla Piattaforma e la sua utilizzazione sono soggetti a condizioni e limitazioni in conformità con la normativa applicabile e con quanto previsto dalle presenti Condizioni Generali. L'utilizzo della Piattaforma è riservato ai soli maggiorenni e a coloro che hanno la capacità legale di assumere impegni giuridici.<br/><br/>
        Le presenti Condizioni Generali costituiscono l'intero accordo tra l'utente e la Società riguardo all'utilizzo della Piattaforma e sostituiscono tutti gli accordi e le intese precedenti, scritti o verbali, tra l'utente e la Società.<br/><br/>
        Se una qualsiasi disposizione delle presenti Condizioni Generali viene ritenuta invalida o inapplicabile, tale disposizione verrà interpretata in modo da riflettere le intenzioni delle parti, nel rispetto della normativa applicabile, e le restanti disposizioni rimarranno in vigore e continueranno ad essere vincolanti per le parti.<br/><br/>
        La mancata applicazione o il ritardo nell'applicazione di qualsiasi disposizione delle presenti Condizioni Generali non costituirà una rinuncia a tale disposizione.
      </div>

      <h2>2. Parti interessate</h2>
      <div>
        Questo contratto ("Contratto") è stipulato tra la Società e l'utente registrato sulla Piattaforma ("Utente").
      </div>

      <h2>3. Servizi forniti dalla Piattaforma</h2>
      <div>
        La Società fornisce una Piattaforma peer-to-peer (“Piattaforma) che consente agli Utenti di noleggiare temporaneamente oggetti fisici, erogare micro servizi/task tra Utenti facenti parte la comunità e vendere gli oggetti come “seconda mano”.
      </div>

      <h2>4. Registrazione dell'Utente</h2>
      <div>
        Per accedere alla Piattaforma e utilizzare i servizi forniti dalla Società, l'Utente deve registrarsi sulla Piattaforma. <br/><br/>
        L'Utente è responsabile di fornire informazioni accurate e aggiornate al momento della registrazione e di mantenere tali informazioni durante l'uso della Piattaforma.
      </div>

      <h2>5. Servizi erogati dalla Piattaforma</h2>
      <div>
        La Piattaforma consente agli utenti di poter fruire di diverse tipologie di servizi, di seguito descritti:
        <div>
          <h3>a. Noleggio temporaneo di oggetti fisici</h3>
          <div>
            Gli Utenti potranno:
            <ul>
              <li>pubblicare annunci per noleggiare beni di proprietà ad altri utenti della Piattaforma per un periodo temporaneo (Renter)
              </li>
              <li>acquistare il diritto di utilizzo temporaneo di un bene (Rentee) </li>
            </ul>
          </div>
          <div>
            Il Renter è responsabile di:
            <ul>
              <li>pagare il Rentee attraverso la Piattaforma prima di ritirare il bene.
              </li>
              <li>mantenere l'oggetto in buone condizioni durante il periodo di noleggio.
              </li>
              <li>restituire l'oggetto noleggiato alla fine del periodo di noleggio.
              </li>
              <li>sostenere eventuali costi a fronte di danni causati al bene durante l’utilizzo, che non siano riconducibili a usura del bene stesso.</li>
            </ul>
          </div>
          <div>
            Il Rentee è responsabile di:
            <ul>
              <li>mettere a disposizione della community beni che siano in condizioni e stato di usura coerente con quanto documentato nelle foto e/o nelle descrizioni dell’annuncio.
              </li>
              <li>sostenere eventuali costi per riparare il bene, qualora a fronte di usura dovesse manifestarsi un malfunzionamento.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3>b. Prestazione di micro servizi/task tra peer</h3>
          <div>
            Gli Utenti possono offrire micro servizi/task ad altri Utenti sulla Piattaforma. <br/>
            L'Utente che presta il servizio/task è responsabile di fornire il servizio/task in modo professionale e rispettoso verso l'Utente che riceve il servizio/task. <br/>
            L'Utente che riceve il servizio/task è responsabile di pagare l'Utente che presta il servizio/task.
          </div>
        </div>
        <div>
          <h3>c. Cessione degli oggetti fisici come seconda mano (vendita)</h3>
          <div>
            Gli Utenti possono cedere definitivamente i beni di seconda mano ad altri Utenti sulla Piattaforma. <br/>
            L'Utente che vende l'oggetto è responsabile di fornire un'accurata descrizione dell'oggetto e delle sue condizioni.<br/>
            L'Utente che acquista l'oggetto è responsabile di pagare l'Utente che vende l'oggetto e di controllare l'oggetto per assicurarsi che sia conforme alla descrizione fornita.
          </div>
        </div>
        <div>
          <h2>
            6. Pubblicazione degli annunci
          </h2>
          <div>
            Prima della pubblicazione degli annunci di noleggio sulla Piattaforma, gli annunci stessi saranno sottoposti ad una verifica da parte della Società al fine di garantire la conformità alle normative vigenti e ai Termini e Condizioni della Piattaforma. <br/><br/>
            La Società si riserva il diritto di non approvare o di rimuovere annunci che violino le leggi applicabili o i Termini e Condizioni della Piattaforma. Inoltre, la Società si riserva il diritto di richiedere ulteriori informazioni o documentazione agli Utenti prima di approvare un annuncio di noleggio.<br/><br/>
            L'approvazione degli annunci di noleggio può richiedere fino a 24 ore dalla loro pubblicazione. In caso di problemi o dubbi sulla pubblicazione degli annunci, gli Utenti possono contattare il servizio clienti della Società.
          </div>
        </div>
        <div>
          <h2>
            7. Sistema di valutazione e rating degli Utenti
          </h2>
          <div>
            La Piattaforma mette a disposizione degli Utenti un sistema di valutazione e rating per consentire a chi noleggia o fruisce di micro servizi di valutare la qualità del servizio ricevuto e/o del bene noleggiato. <br/><br/>
            Gli Utenti saranno invitati a lasciare una valutazione e un feedback in merito alla prestazione e/o al bene ricevuto, utilizzando un sistema di rating basato su una scala da 1 a 5 stelle, dove 5 stelle rappresentano il massimo punteggio.<br/><br/>
            La valutazione sarà visibile agli altri Utenti della Piattaforma e sarà utilizzata per fornire un feedback utile ai membri della comunità di Utenti. <br />
            Il sistema di valutazione aiuterà anche la Piattaforma a monitorare e migliorare la qualità del servizio offerto.
          </div>
        </div>
        <div>
          <h2>
            8. Commissioni per i servizi erogati
          </h2>
          <div>
            La Società addebiterà una commissione sui servizi forniti sulla Piattaforma, come di seguito specificato:
          </div>
          <h3>a. Noleggio temporaneo di oggetti fisici</h3>
          <div>
            Per ogni transazione di noleggio conclusa attraverso la piattaforma, verrà trattenuta al proprietario del bene una commissione del 20% (venti per cento) del prezzo di noleggio. Tale commissione servirà a coprire i costi operativi della Piattaforma e del servizio di supporto clienti.
            Non sarà invece addebitata alcuna commissione al noleggiante.
          </div>
          <h3>b. Prestazione di micro servizi/task tra peer
          </h3>
          <div>
            Per ogni transazione di acquisto di un micro servizio/prestazione conclusa attraverso la Piattaforma, verrà trattenuta una commissione del 20% (venti per cento) del prezzo stabilito per il servizio. Tale commissione servirà a coprire i costi operativi della Piattaforma e del servizio di supporto clienti.
            Non sarà invece addebitata alcuna commissione al richiedente il servizio.
          </div>
          <h3>c. Cessione degli oggetti fisici come seconda mano (vendita)
          </h3>
          <div>
            Per ogni transazione di vendita di seconda mano conclusa attraverso la Piattaforma, verrà trattenuta una commissione del 5% (cinque per cento) del prezzo stabilito per la cessione del bene. Tale commissione servirà a coprire i costi operativi della Piattaforma e del servizio di supporto clienti. Non sarà invece addebitata alcuna commissione al richiedente il servizio.
          </div>
        </div>
        <div>
          <h2>
            9. Pagamenti
          </h2>
          <div>
            L'Utente che noleggia un oggetto o riceve un servizio/task è responsabile di effettuare il pagamento all'Utente che fornisce l'oggetto o il servizio/task. I pagamenti devono essere effettuati solo tramite la Piattaforma, utilizzando i metodi di pagamento accettati dalla Società.<br /><br />
            La Piattaforma accetta pagamenti tramite carta di credito/debito. Per garantire la sicurezza delle transazioni, i pagamenti saranno elaborati attraverso un gateway di pagamento terzo-party chiamato Stripe.<br /><br />
            Per effettuare un pagamento, l'Utente dovrà fornire le informazioni richieste sulla carta di credito/debito. La Piattaforma non memorizza le informazioni sulla carta di credito/debito dell'Utente. Tutte le informazioni relative alla transazione sono crittografate e trasmesse direttamente a Stripe per l'elaborazione del pagamento. <br /><br />
            Il pagamento totale per i servizi erogati, incluse le commissioni di transazione, sarà addebitato sulla carta di credito/debito dell'Utente al momento della conferma della prenotazione.
          </div>
        </div>
        <div>
          <h2>
            10. Donazioni
          </h2>
          <div>
            La Società si impegna a devolvere l'1% del margine di ogni transazione completata a progetti allineati agli SDG (Sustainable Development Goals) dell'ONU.<br /><br />
            Gli utenti della Piattaforma avranno la possibilità di scegliere, durante il check out, uno dei progetti preselezionati a cui destinare l'1% del margine della Società. <br /><br />
            Tale importo non verrà addebitato all'Utente ma detratto dalla commissione prevista per la Società e verrà devoluto direttamente all'associazione tramite blockchain in modo da garantire la trasparenza e la certificazione del buon esito della donazione.<br /><br />
            La Società si riserva il diritto di aggiornare l'elenco dei progetti selezionati per le donazioni.
          </div>
        </div>
      </div>
      <div>
        <h2>
          11. Cancellazione dell'account
        </h2>
        <div>
          Gli Utenti possono richiedere la cancellazione del proprio account in qualsiasi momento contattando il servizio clienti della Società.<br /><br />
          In caso di violazione dei Termini e Condizioni della Piattaforma, la Società si riserva il diritto di cancellare l'account di un Utente senza preavviso o responsabilità nei suoi confronti. <br /><br />
          In caso di cancellazione dell'account, la Società cancellerà tutti i dati personali dell'Utente entro un periodo di tempo ragionevole, tranne i dati che la Società è tenuta a conservare per legge.<br /><br />
          L'Utente accetta che, in caso di cancellazione dell'account, non potrà più accedere ai servizi della Piattaforma e che la Società non sarà responsabile per eventuali perdite o danni subiti dall'Utente a causa della cancellazione dell'account.
        </div>
      </div>
      <div>
        <h2>
          12. Buone pratiche di cura per gli oggetti noleggiati
        </h2>
        <div>
          Gli Utenti che noleggiano oggetti fisici attraverso la Piattaforma sono tenuti a prestare la massima cura per garantire che i beni noleggiati siano restituiti in buone condizioni. Gli Utenti devono utilizzare gli oggetti noleggiati solo per lo scopo per cui sono stati noleggiati, mantenendoli al sicuro e non esponendoli ad alcun rischio di danni, furti o perdite. Inoltre, gli Utenti devono utilizzare gli oggetti noleggiati solo in conformità con le leggi e le regolamentazioni applicabili.<br /><br />
          In generale l’Utente dovrebbe tenere in considerazione alcune buone pratiche, a titolo esemplificativo e non esaustivo, per preservare i beni noleggiati:
          <ul>
            <li>Utilizzare l'oggetto noleggiato solo per lo scopo previsto.
            </li>
            <li>Non esporre l'oggetto noleggiato a rischi di danni, furti o perdite.
            </li>
            <li>Mantenere l'oggetto noleggiato al sicuro.
            </li>
            <li>Non utilizzare l'oggetto noleggiato in modo inappropriato o in violazione delle leggi e delle regolamentazioni applicabili.
            </li>
            <li>Non prestare il bene a terzi durante il periodo di noleggio.
            </li>
            <li>Restituire l'oggetto noleggiato in buone condizioni alla scadenza del periodo di noleggio.
            </li>
          </ul>
        </div>
      </div>
      <div>
        <h2>
          13. Limitazione di responsabilità
        </h2>
        <div>
          La Società non è responsabile per eventuali danni che dovessero emergere durante il periodo di noleggio e/o durante l’erogazione delle prestazioni di servizi. In particolare:
        </div>
        <h3>a. Responsabilità in caso di noleggio di beni
        </h3>
        <div>
          Il Renter è responsabile per eventuali danni o perdite che si verificano durante il periodo di noleggio.<br /><br />
          La Società che gestisce la Piattaforma non ha alcuna responsabilità diretta in caso di danni o perdite ai beni noleggiati. <br /><br />
          In caso di controversie tra il Renter ed il Rentee riguardo a danni o perdite ai beni noleggiati, la Società si impegna a mediare tra le parti per giungere a una soluzione equa e ragionevole. <br /><br />
          Tuttavia, il Renter ed il Rentee accettano che la Società non ha alcuna responsabilità diretta per eventuali danni o perdite ai beni noleggiati tramite la Piattaforma. <br /><br />
        </div>
        <h3>b. Responsabilità in caso di prestazioni e micro servizi
        </h3>
        <div>
          L'Utente che presta un servizio/task attraverso la Piattaforma è responsabile per eventuali danni o perdite che si verificano durante la prestazione del servizio. <br /><br />
          La Società che gestisce la Piattaforma non ha alcuna responsabilità diretta in caso di danni o perdite causati dalla prestazione dei servizi. In caso di controversie tra gli Utenti sulla Piattaforma riguardo a danni o perdite causati dalla prestazione dei servizi, la Società si impegna a mediare tra le parti per giungere a una soluzione equa e ragionevole. Tuttavia, l'Utente accetta che la Società non ha alcuna responsabilità diretta per eventuali danni o perdite causati dalla prestazione dei servizi tramite la Piattaforma. <br /><br />
        </div>
        <h3>c. Vendita di beni (seconda mano)
        </h3>
        <div></div>
      </div>
      <div>
        <h2>
          14. Risoluzione delle controversie
        </h2>
        <div>
          In caso di controversie tra gli Utenti sulla Piattaforma, la Società si riserva il diritto di agire come mediatore. Gli Utenti accettano di collaborare con la Società per risolvere le controversie in modo equo e ragionevole.
        </div>
      </div>
      <div>
        <h2>
          15. Legge applicabile e giurisdizione
        </h2>
        <div>
          Questo contratto è regolato dalle leggi dell’Italia e tutte le eventuali controversie saranno risolte esclusivamente dai tribunali di Milano.
        </div>
      </div>
      <div>
        <h2>
          16. Modifiche al contratto
        </h2>
        <div>
          La Società si riserva il diritto di modificare le presenti Condizioni Generali di Servizio in qualsiasi momento senza preavviso e a propria discrezione. Tuttavia, tali modifiche non avranno effetto sulle transazioni già concluse prima della modifica.
        </div>
      </div>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
