import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 10;
const SERVICE = 'service';
const PRODUCT  = 'product';

// ================ Action types ================ //

export const RECENT_ADDED_SERVICES_REQUEST = 'app/LangingPage/RECENT_ADDED_SERVICES_REQUEST';
export const RECENT_ADDED_SERVICES_SUCCESS = 'app/LangingPage/RECENT_ADDED_SERVICES_SUCCESS';
export const RECENT_ADDED_SERVICES_ERROR = 'app/LangingPage/RECENT_ADDED_SERVICES_ERROR';

export const RECENT_ADDED_PRODUCTS_REQUEST = 'app/LangingPage/RECENT_ADDED_PRODUCTS_REQUEST';
export const RECENT_ADDED_PRODUCTS_SUCCESS = 'app/LangingPage/RECENT_ADDED_PRODUCTS_SUCCESS';
export const RECENT_ADDED_PRODUCTS_ERROR = 'app/LangingPage/RECENT_ADDED_PRODUCTS_ERROR';
// ================ Reducer ================ //

const initialState = {
  pagination: null,
  servicesInProgress: false,
  productsInProgress: false,
  servicesError: null,
  productsError: null,
  recentlyAddedServicesIds: [],
  recentlyAddedProductsIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case RECENT_ADDED_SERVICES_REQUEST:
      return {
        ...state,
        servicesInProgress: true,
        servicesError: null,
      };
    case RECENT_ADDED_SERVICES_SUCCESS:
      return {
        ...state,
        recentlyAddedServicesIds: resultIds(payload.data),
        pagination: payload.data.meta,
        servicesInProgress: false,
      };
    case RECENT_ADDED_SERVICES_ERROR:
      return {
        ...state,
        servicesInProgress: false,
        servicesError: payload
      };
    case RECENT_ADDED_PRODUCTS_REQUEST:
      return {
        ...state,
        productsInProgress: true,
        productsError: null,
      };
    case RECENT_ADDED_PRODUCTS_SUCCESS:
      return {
        ...state,
        recentlyAddedProductsIds: resultIds(payload.data),
        pagination: payload.data.meta,
        productsInProgress: false,
      };
    case RECENT_ADDED_PRODUCTS_ERROR:
      return {
        ...state,
        productsInProgress: false,
        productsError: payload
      };
    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const recentlyAddedServicesRequest = () => ({
  type: RECENT_ADDED_SERVICES_REQUEST
});

export const recentlyAddedServicesSuccess = response => ({
  type: RECENT_ADDED_SERVICES_SUCCESS,
  payload: { data: response.data },
});

export const recentlyAddedServicesError = e => ({
  type: RECENT_ADDED_SERVICES_ERROR,
  error: true,
  payload: e,
});

export const recentlyAddedProductsRequest = () => ({
  type: RECENT_ADDED_PRODUCTS_REQUEST
});

export const recentlyAddedProductsSuccess = response => ({
  type: RECENT_ADDED_PRODUCTS_SUCCESS,
  payload: { data: response.data },
});

export const recentlyAddedProductsError = e => ({
  type: RECENT_ADDED_PRODUCTS_ERROR,
  error: true,
  payload: e,
});

export const getRecentlyAddedServices = searchParams => (dispatch, getState, sdk) => {
  dispatch(recentlyAddedServicesRequest());

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    minStock: 1,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(recentlyAddedServicesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(recentlyAddedServicesError(storableError(e)));
      throw e;
    });
};

export const getRecentlyAddedProducts = searchParams => (dispatch, getState, sdk) => {
  dispatch(recentlyAddedProductsRequest());

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    minStock: 1,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(recentlyAddedProductsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(recentlyAddedProductsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => dispatch => {
  const serviceType = {pub_type: SERVICE}
  const productType = {pub_type: PRODUCT}
  const paramsQuery = {
    page: 1,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  }
  return Promise.all([
    dispatch(getRecentlyAddedServices({...serviceType, ...paramsQuery})),
    dispatch(getRecentlyAddedProducts({...productType, ...paramsQuery}))
  ]);
};
