import React, { useState } from 'react';
import Select from 'react-select';

import css from './FieldMultiSelect.module.css';

const FieldMultiSelect = ({
  options,
  placeholder,
  input: { value, name, onChange, ...restInput },
  meta,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  return (
    <Select
      classNames={{
        control: (state) => state.isFocused || state.isSelected ? css.active : css.input,
        valueContainer: (state) => css.valueHoler,
        multiValue: () => css.values,
        dropdownIndicator: () => css.indicatorStyle,
      }}
      blurInputOnSelect={false}
      placeholder={placeholder}
      displayEmpty
      {...rest}
      name={name}
      inputProps={restInput}
      error={meta.error && meta.touched}
      onChange={onChange}
      value={[...value]}
      options={options}
      isMulti
      closeMenuOnSelect={false}
    />
  );
};

export default FieldMultiSelect;
