import React from 'react';
import { FieldSelect } from '../../../components';
import { intlShape } from '../../../util/reactIntl';
import config from '../../../config';

import css from './OccupationSelectionField.module.css';

const OccupationSelectionField = ({intl}) => {
  const {occupations} = config.custom;
  const occupationLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.occupationLabel',
  });
  const occupationPlaceholder = intl.formatMessage({
    id: 'ProfileSettingsForm.occupationPlaceholder',
  });

  return occupations ? (
    <FieldSelect
      className={css.occupationInput}
      name='occupation'
      id='occupation'
      label={occupationLabel}
    >
      <option disabled value="">
        {occupationPlaceholder}
      </option>
      {occupations.map((occupation) => (
        <option key={occupation.key} value={occupation.key}>
          {occupation.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

OccupationSelectionField.propTypes = {
  intl: intlShape.isRequired,
};

export default OccupationSelectionField;
