import React from 'react'
import { FieldTextInput } from '../../../components';
import css from './InstagramField.module.css';

const InstagramField = ({intl}) => {
  const instagramMessage = intl.formatMessage({ id: 'EditListing.instagram' });
  const instagramPlaceholderMessage = intl.formatMessage({
    id: 'EditListing.instagramPlaceholder',
  });

  return (
    <FieldTextInput
      id="instagram"
      name="instagram"
      className={css.instagram}
      type="text"
      label={instagramMessage}
      placeholder={instagramPlaceholderMessage}
    />
  )
}
 export default InstagramField;
