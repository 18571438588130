import React, { Component } from 'react';
import { arrayOf, func, node, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './CommunityCodeFilter.module.css';
import CommunityIcon from './CommunityIcon';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'hasCommunityCode';
};

class CommunityCodeFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {active: false};
    this.handleOnClick = this.handleOnClick.bind(this);
  }



  handleOnClick(){
    this.setState({active: !this.state.active})
    if(!this.state.active){
      const { onSubmit, queryParamNames } = this.props;
      this.setState({ isOpen: false });
      const communityCodeQueryParamName = getQueryParamName(queryParamNames);
      onSubmit({[communityCodeQueryParamName]: true});
    } else {
      const { onSubmit, queryParamNames } = this.props;
      this.setState({ isOpen: false });
      const communityCodeQueryParamName = getQueryParamName(queryParamNames);
      onSubmit({[communityCodeQueryParamName]: null});
    }
  }


  render() {
    const {
      showAsPopup: isDesktop,
      label,
    } = this.props
    const { active } = this.state
    const labelStyles = active ? css.labelSelected : css.label;
    const className = active ? css.iconSelected : css.icon;

    return (
      <div
        onClick={this.handleOnClick}
        className={labelStyles}
      >
        { isDesktop ? label : <CommunityIcon title={label} className={className} />}
      </div>
    )
  }
}

CommunityCodeFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

CommunityCodeFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(CommunityCodeFilter);
