import one from './1.png'
import two from './2.png'
import three from './3.png'
import four from './4.png'
import five from './5.png'
import six from './6.png'
import seven from './7.png'
import eight from './8.png'
import nine from './9.png'
import ten from './10.png'
import eleven from './11.png'
import towels from './12.png'
import thirteen from './13.png'
import fourteen from './14.png'
import fifteen from './15.png'
import sixteen from './16.png'
import seventeen from './17.png'


export const sdgs = [
  {
    1: one,
  },
  {
    2: two,
  },
  {
    3: three,
  },
  {
    4: four,
  },
  {
    5: five,
  },
  {
    6: six,
  },
  {
    7: seven,
  },
  {
    8: eight,
  },
  {
    9: nine,
  },
  {
    10: ten,
  },
  {
    11: eleven,
  },
  {
    12: towels,
  },
  {
    13: thirteen,
  },
  {
    14: fourteen,
  },
  {
    15: fifteen,
  },
  {
    16: sixteen,
  },
  {
    17: seventeen,
  },
];
