import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import item1 from './images/marco_rossi.jpg';
import item2 from './images/chiara_bianchi.jpg';
import item3 from './images/davide_moretti.jpg';
import item4 from './images/alessandro_ferrari.jpg';


import css from './SectionWhatCommunitySaysAboutUs.module.css';


class EaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(EaselyImage);

const Image = ({src, title}) => (
  <div className={css.imageWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.easelyImage} />
    </div>
  </div>
);

const SectionWhatCommunitySaysAboutUs = () => {
  return (
    <article className={css.root}>
      <div>
        <h1 className={css.title}>
          <FormattedMessage id="SectionWhatCommunitySaysAboutUs.titleHead" />
          <span className={css.highlightedByColor}>
            {" "}<FormattedMessage id="SectionWhatCommunitySaysAboutUs.titleMiddle" />{" "}
          </span>
        </h1>
      </div>
      <div className={css.contentWrapper}>
        <div className={css.contentItem}>
          <Image src={item1} title="test" />
          <div>
            <p className={css.description}>Ho utilizzato Easely per procurarmi un martello e sono rimasto stupito dalla rapidità del processo. In meno di 45 minuti ho avuto a disposizione l'attrezzo di cui avevo bisogno per arredare il mio appartamento.</p>
            <h3 className={css.name}>MARCO ROSSI,</h3>
            <h4 className={css.tag}>STUDENTE</h4>
          </div>
        </div>
        <div className={css.contentItem}>
          <Image src={item2} title="test" />
          <div>
            <p  className={css.description}>
              Amo viaggiare e ho deciso di organizzare un viaggio in Norvegia. Easely mi ha aiutato a partire senza sovraccaricare il bagaglio con cose che non avrei mai più usato. Ho trovato l’abbigliamento e l’attrezzatura perfetta senza dover svuotare il mio portafoglio.
            </p>
            <h3 className={css.name}>CHIARA BIANCHI,</h3>
            <h4 className={css.tag}>IMPIEGATA</h4>
          </div>
        </div>
        <div className={css.contentItem}>
          <Image src={item3} title="test" />
          <div>
            <p  className={css.description}>
              Fantastico per i freelancer! Come libero professionista che ama lavorare a diverse cose, ho caricato i miei servizi di designer e interprete su Easely e devo dire che è stata una scelta eccellente. La piattaforma mi ha permesso di trovare facilmente le persone giuste che avevano bisogno delle mie competenze!
            </p>
            <h3 className={css.name}>DAVIDE MORETTI,</h3>
            <h4 className={css.tag}>LIBERO PROFESSIONISTA</h4>
          </div>
        </div>
        <div className={css.contentItem}>
          <Image src={item4} title="test" />
          <div>
            <p  className={css.description}>
              Avevo accumulato una quantità considerevole di oggetti inutilizzati a casa, ma non volevo liberarmene. Grazie a Easely, ho potuto caricarli in pochissimi secondi e noleggiarli in brevissimo tempo. La cosa che mi ha sorpreso di più è che ho anche deciso di vendere una parte di questi oggetti, che sapevo non mi sarebbero più serviti.
            </p>
            <h3 className={css.name}>ALESSANDRO FERRARI,</h3>
            <h4 className={css.tag}>IMPIEGATO</h4>
          </div>
        </div>
      </div>
    </article>
  )
}

export default SectionWhatCommunitySaysAboutUs;
