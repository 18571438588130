import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Arrow } from "@egjs/flicking-plugins";

// import styles
import "../../../assets/flicking.css"; // Supports IE10+, using CSS flex: ;
// Or...
import "../../../assets/flicking-inline.css"; //Supports IE9+, using CSS inline-box
import "../../../assets/arrow.css";

import css from './DonationProjectCards.module.css';
import donationData  from '../../../assets/donation/data/donation.json';
import { sdgs } from '../../../assets/donation/sdg';
import { ExternalLink } from '../..';
import ArrowRight from './ArrowRight';
import ArrowLeft from './ArrowLeft';
import { FormattedMessage } from 'react-intl';

const DonationProjectCards = ({isMobileLayout}) => {
  const flickingRef = useRef();
  const plugins = [ new Arrow() ];
  const data = JSON.parse(JSON.stringify(donationData));
  const [cardId, setCardId] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [activePanel, setActivePanel] = useState(false);
  const pluginsProp = {plugins}

  const flickingProps = {
    viewportTag: "div",
    cameraTag: "div",
    cameraClass: "",
    renderOnSameKey: false,
    align: "center",
    onMove: (e) => {},
    onWillChange: (e) => {
      setActivePanel(e.index)
    },
    horizontal: true,
    circular: true,
    hideBeforeInit: true,
    ...pluginsProp,
  }

  const arrowPrevClasses = classNames('flicking-arrow-prev', css.arrow)
  const arrowNextClasses = classNames('flicking-arrow-next', css.arrow)

  useEffect(() => {
    if(flickingRef.current){
      setActivePanel(flickingRef.current?.index);
    }
  }, [flickingRef.current])

  return (
    <Flicking {...flickingProps} ref={flickingRef}>
      {data.length > 0 && data.map((d, index) => {
        const descriptionLen = d.description.length;
        const slicedDescription = descriptionLen > 160
          ? d.description.slice(0, 160)
          : d.description;

        const normalDescription = d.description;
        const panelClasses = classNames(css.flickingPanel, {
          [css.activePanel]: index === activePanel,
        });

        return (
          <section className={panelClasses} key={index}>
            <div>
              <div className={css.aspectWrapper}>
                <img
                  className={css.rootForImage}
                  src={d.image}
                  alt={d.title}
                />
              </div>
              <ExternalLink href={d.url}>
                <h3 className={css.title}>
                  {d.title}
                </h3>
              </ExternalLink>
              <p className={css.description}>
                {
                  cardId === d.id
                    ? toggle ? normalDescription : slicedDescription
                    : slicedDescription
                }
                {descriptionLen > 160 ? (
                  <>
                    {cardId === d.id ? (
                      <>
                        {!toggle ? (
                          <span
                            className={css.showLesMoreBtn}
                            onClick={() => {
                              setToggle(true);
                              setCardId(d.id)
                            }}
                          >
                            <FormattedMessage id="StripePaymentForm.donationCardShowMore" />
                          </span>
                        ) : (
                          <span
                            className={css.showLesMoreBtn}
                            onClick={() => {
                              setToggle(false);
                              setCardId(d.id)
                            }}
                          >
                            <FormattedMessage id="StripePaymentForm.donationCardShowLess" />
                          </span>
                        )}
                      </>
                    ) : (
                      <span
                        className={css.showLesMoreBtn}
                        onClick={() => {
                          setToggle(true);
                          setCardId(d.id)
                        }}
                      >
                       <FormattedMessage id="StripePaymentForm.donationCardShowMore" />
                      </span>
                    )}
                  </>
                ) : null }
              </p>
              <div className={css.sdgsWrapper}>
                {d.sdg.length > 0 && d.sdg.map((s, index)=> {
                  const imgSrc = sdgs.find(sg => sg[s])
                  return imgSrc ? (
                    <ExternalLink key={index} href={`https://sdgs.un.org/goals/goal${s}`}>
                      <img
                        className={css.sdgsImages}
                        width="30px"
                        height="30px"
                        src={imgSrc[s]}
                        alt=""
                      />
                    </ExternalLink>
                  ): null;
                })}
              </div>
            </div>
          </section>
        )
      })}
      <ViewportSlot>
        <span className={arrowPrevClasses}>
          <ArrowLeft />
        </span>
        <span className={arrowNextClasses}>
          <ArrowRight />
        </span>
      </ViewportSlot>
    </Flicking>
  )
}

export default DonationProjectCards;
