import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATE } from '../../util/types';
import { BookingBreakdown } from '../../components';

import css from './TransactionPanel.module.css';
import { ensureBooking } from '../../util/data';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const { className, rootClassName, breakdownClassName, transaction, transactionRole } = props;
  const bookingTxLoaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const saleTxLoaded = transaction?.id && transaction.attributes.lineItems?.length > 0;


  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  const txBookingMaybe = transaction.booking?.id
    ? { booking: ensureBooking(transaction.booking), dateType: DATE_TYPE_DATE }
    : {};

  return bookingTxLoaded || saleTxLoaded ? (
    <div className={classes}>
      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={bookingTxLoaded ? config.bookingUnitType : config.buyingUnitType}
        transaction={transaction}
        // booking={transaction.booking}
        // dateType={DATE_TYPE_DATE}
        {...txBookingMaybe}
      />
    </div>
  ) : null;
};

export default BreakdownMaybe;
