import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import image from './images/try-easely.png';
import earnMoneyImage from './images/earn-money.png';
import saveMoneyImage from './images/save-money.png';
import saveTimeImage from './images/save-time.png';
import impactImage from './images/impact.png';

import css from './SectionTryEasely.module.css';

class TryEaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(TryEaselyImage);

const Image = ({src, title, rootClassName}) => (
  <div className={rootClassName}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.tryEaselyImage} />
    </div>
  </div>
);

const SectionTryEasely = () => {
  return (
    <article className={css.root}>
      <h1 className={css.sectionTryEaselyTitle}>
        <FormattedMessage id="SectionTryEasely.titleHead" />
        <span className={css.highlighted}>
          {" "}<FormattedMessage id="SectionTryEasely.titleMiddle" />{" "}
        </span>
        <FormattedMessage id="SectionTryEasely.titleTail" />
      </h1>
      <div className={css.sectionTryEaselyContent}>
        <Image src={image} rootClassName={css.imageWrapper} title="Try Easely" />
        <div className={css.whyEaselyWrapper}>
          <div>
            <Image src={earnMoneyImage} rootClassName={css.iconWrapper} title="Earn Money" />
            <h3 className={css.whyEaselyTitle}><FormattedMessage id="SectionTryEasely.earnMoneyTitle" /></h3>
            <p className={css.whyEaselySubtitle}><FormattedMessage id="SectionTryEasely.earnMoneySubtitle" /></p>
          </div>
          <div>
            <Image src={saveMoneyImage} rootClassName={css.iconWrapper} title="Save Money" />
            <h3 className={css.whyEaselyTitle}><FormattedMessage id="SectionTryEasely.saveMoneyTitle" /></h3>
            <p className={css.whyEaselySubtitle}><FormattedMessage id="SectionTryEasely.saveMoneySubtitle" /></p>
          </div>
          <div>
            <Image src={saveTimeImage} rootClassName={css.iconWrapper} title="Save Time" />
            <h3 className={css.whyEaselyTitle}><FormattedMessage id="SectionTryEasely.saveTimeTitle" /></h3>
            <p className={css.whyEaselySubtitle}><FormattedMessage id="SectionTryEasely.saveTimeSubtitle" /></p>
          </div>
          <div>
            <Image src={impactImage} rootClassName={css.iconWrapper} title="Make an impact" />
            <h3 className={css.whyEaselyTitle}><FormattedMessage id="SectionTryEasely.impactTitle" /></h3>
            <p className={css.whyEaselySubtitle}><FormattedMessage id="SectionTryEasely.impactSubtitle" /></p>
          </div>
        </div>
      </div>
    </article>
  )
}

export default SectionTryEasely;
