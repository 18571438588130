import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingDiscount.module.css';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes

/**
 * Calculates discounted price for listing based on discount percentage.
 * The price will be `price * (percentage / 100)`.
 * @param { Money } price SDK Money object
 * @param { number } percentage discount percentage
 * @returns { Money } discounted price in SDK Money object format
 */

function getDiscountedPrice(price, percentage){
  // check has price?
  const hasPrice = price && typeof price.amount === 'number';
  // check has percentage?
  const hasPercentage = typeof percentage === 'number';

  const priceAmount = hasPrice ? price.amount : 0;
  const priceToBeDiscounted = hasPrice && hasPercentage ? priceAmount * (percentage / 100) : 0;

  const newPrice =  priceAmount - priceToBeDiscounted;
  const discountedPrice = new Money(newPrice, config.currency);
  return discountedPrice
}

const ListingDiscount = ({
  title,
  price,
  percentage,
  priceData,
  intl,
  unitTranslationKey,
  hasMultipleDiscounts = false
}) => {
  const discountedPrice = getDiscountedPrice(price, percentage);
  const { formattedPrice, priceTitle } = priceData(discountedPrice, intl);

  const desktopDiscountPrice = classNames(css.desktopDiscountPrice, {
    [css.rightMargin] : hasMultipleDiscounts,
  })

  return (
    <div className={desktopDiscountPrice}>
      <div className={css.desktopDiscountPriceTag}>{title}</div>
      <div className={css.desktopDiscountPriceLayout}>
        <div className={css.desktopDiscountPriceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        <span className={css.discountPriceDivider}>/</span>
        <div className={css.desktopDiscountPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>
    </div>
  )
}

export default ListingDiscount;
