import React from 'react'
import { FieldTextInput } from '../../../components';
import css from './TiktokField.module.css';

const TiktokField = ({intl}) => {
  const tikTokMessage = intl.formatMessage({ id: 'EditListing.tikTok' });
  const tikTokPlaceholderMessage = intl.formatMessage({
    id: 'EditListing.tikTokPlaceholder',
  });

  return (
    <FieldTextInput
      id="tiktok"
      name="tiktok"
      className={css.tiktok}
      type="text"
      label={tikTokMessage}
      placeholder={tikTokPlaceholderMessage}
    />
  )
}

export default TiktokField;
