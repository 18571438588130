import React from 'react'

export default function ArrowDown({className}) {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      x="0px"
      y="0px"
      id="svg-1ecf"
    >
      <path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z">
      </path>
    </svg>
  )
}
