import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';


import css from './SectionTopCategories.module.css';
import config from '../../config';
import { combinedProductAndServiceOptions } from '../../util/search';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const SectionTopCategories = ({history}) => {
  const { filters } = config.custom;
  const categoryFilters = filters.filter(f => f.id === 'category');
  const categoryFiltersConfig = categoryFilters[0].config;
  const categories = categoryFiltersConfig.product && categoryFiltersConfig.service
    ? combinedProductAndServiceOptions(categoryFiltersConfig)
    : [];

  const handleOnClick = (key) => {
    const searchParams = {
      pub_category: key
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  return (
    <article className={css.root}>
      <div>
        <h1 className={css.sectionTopCategoriesTitle}>
            {" "}<FormattedMessage id="SectionTopCategories.titleHead" /> {" "}
        <span className={css.highlightedByColor}>
          <FormattedMessage id="SectionTopCategories.titleTail" />
          </span>
        </h1>
      </div>
      <div className={css.sectionTopCategoriesConetnt}>
        {categories.map(category => (
          <button
            key={category.key}
            onClick={() => handleOnClick(category.key)}
            className={css.categoryBtn}
          >
           {category.label}
          </button>
        ))}
      </div>
    </article>
  )
}

export default SectionTopCategories;
