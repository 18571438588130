import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';

import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { composeValidators, autocompleteSearchRequired, autocompletePlaceSelected } from '../../../util/validators';
import { LocationAutocompleteInputField } from '../../../components';

import css from '../EditListingForm.module.css';

const identity = v => v;


const LocationField = ({values, intl, isService}) => {
  const asterisk = (
    <span className={css.asterisk}>*</span>
  );

  const address = isService ? "Indirizzo" : "Indirizzo per ritiro / consegna"
  const titleLocationRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.address'
  }, {address, asterisk});

  const addressPlaceholderMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressPlaceholder',
  });
  const addressRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressRequired',
  }, {address});
  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressNotRecognized',
  }, {address});

  return (
    <LocationAutocompleteInputField
      className={css.locationAddress}
      inputClassName={css.locationAutocompleteInput}
      iconClassName={css.locationAutocompleteInputIcon}
      predictionsClassName={css.predictionsRoot}
      validClassName={css.validLocation}
      name="location"
      label={titleLocationRequiredMessage}
      placeholder={addressPlaceholderMessage}
      useDefaultPredictions={false}
      format={identity}
      valueFromForm={values.location}
      validate={composeValidators(
        autocompleteSearchRequired(addressRequiredMessage),
        autocompletePlaceSelected(addressNotRecognizedMessage)
      )}
    />
  )
}

export default LocationField
