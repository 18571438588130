import React from 'react';
import Switch from "react-switch";
import config from '../../config';

import css from './Switcher.module.css'

const Switcher = ({checked, onChange, label}) => {
  const {marketplaceColor, marketplaceColorDark} = config;

  return (
    <label
      className={css.root}
      htmlFor="switcher-component"
    >
      <span className={css.switcherLabel}>{label}</span>
      <Switch
        checked={checked}
        onChange={onChange}
        offColor="#b2b2b2"
        offHandleColor="#e7e7e7"
        onColor={marketplaceColor}
        onHandleColor={marketplaceColorDark}
        handleDiameter={26}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={18}
        width={48}
        className="react-switch"
        id="switcher-component"
      />
    </label>
  )
}

export default Switcher;
