import React from 'react';
import css from './SectionMakeImpact.module.css';

const CheckmarkIcon = () => {
  return (
    <div className={css.iconWrapper}>
      <svg
        className={css.checkMarkIcon}
        viewBox="0 0 512 512"
        id="svg-e2de"
      >
        <path d="m433.1 67.1-231.8 231.9c-6.2 6.2-16.4 6.2-22.6 0l-99.8-99.8-78.9 78.8 150.5 150.5c10.5 10.5 24.6 16.3 39.4 16.3 14.8 0 29-5.9 39.4-16.3l282.7-282.5z"></path>
      </svg>
    </div>
  )
}

export default CheckmarkIcon;
