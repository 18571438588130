
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import Flicking from "@egjs/react-flicking";

// import styles
import "../../assets/flicking.css"; // Supports IE10+, using CSS flex: ;
// Or...
import "../../assets/flicking-inline.css"; //Supports IE9+, using CSS inline-box
import css from './SectionMonetizeYourItems.module.css';

import listIcon from './icons/list.png';
import approveIcon from './icons/approve.png';
import provideServicesIcon from './icons/provide-services.png';
import monetizeIcon from './icons/monetize.png';
import MonetizeCard from '../MonetizeCard/MonetizeCard';

const monetizeItems = [
  {
    icon: listIcon,
    id: 'SectionMonetizeYourItems.list.title',
    description: 'SectionMonetizeYourItems.list.description',
  },
  {
    icon: approveIcon,
    id: 'SectionMonetizeYourItems.approve.title',
    description: 'SectionMonetizeYourItems.approve.description',
  },
  {
    icon: provideServicesIcon,
    id: 'SectionMonetizeYourItems.rentOrSell.title',
    description: 'SectionMonetizeYourItems.rentOrSell.description',
  },
  {
    icon: monetizeIcon,
    id: 'SectionMonetizeYourItems.monetize.title',
    description: 'SectionMonetizeYourItems.monetize.description',
  },
]

const SectionMonetizeYourItems = () => {
  const flickingProps = {
    viewportTag: "div",
    cameraTag: "div",
    cameraClass: css.flickingRoot,
    renderOnSameKey: false,
    align: "center",
    onMove: (e) => {},
    onWillChange: (e) => {},
    horizontal: true,
    circular: true,
    hideBeforeInit: true,
    firstpanelsize: "100%",
  }

  return (
    <article className={css.root}>
      <div>
        <h1 className={css.title}>
          <FormattedMessage id="SectionMonetizeYourItems.titleHead" />
          <span className={css.highlightedByColor}>
            {" "}<FormattedMessage id="SectionMonetizeYourItems.titleMiddle" />{" "}
          </span>
          <FormattedMessage id="SectionMonetizeYourItems.titleTail" />
        </h1>
      </div>
      <Flicking {...flickingProps}>
        {monetizeItems.map((item) => (
          <div className={css.flickingPanel} key={item.id}>
            <MonetizeCard item={item} />
          </div>
        ))}
      </Flicking>
    </article>
  )
}

export default SectionMonetizeYourItems;
