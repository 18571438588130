import React from 'react'
import { FieldTextInput } from '../../../components';
import css from './LinkedinField.module.css';

const LinkedinField = ({intl}) => {
  const linkedinMessage = intl.formatMessage({ id: 'EditListing.linkedin' });
  const linkedinPlaceholderMessage = intl.formatMessage({
    id: 'EditListing.linkedinPlaceholder',
  });

  return (
    <FieldTextInput
      id="linkedin"
      name="linkedin"
      className={css.linkedin}
      type="text"
      label={linkedinMessage}
      placeholder={linkedinPlaceholderMessage}
    />
  )
}

export default LinkedinField;
