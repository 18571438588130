import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AddIcon from './icons/AddIcon';
import ArrowDown from './icons/ArrowDown';
import FAQs from './faq_it.json';

import css from './SectionFAQ.module.css';

const SectionFAQ = () => {
  const faqs = JSON.parse(JSON.stringify(FAQs));
  const [expand, setExpand] = useState(Object.keys(faqs)[0]);

  const handleExpanded = (key) => {
    if(expand === key) {
      setExpand(null);
    } else {
      setExpand(key);
    }
  }

  return (
    <article className={css.root}>
      <h1 className={css.sectionGaqTitle}><FormattedMessage id="SectionFAQ.title" /></h1>
      <p className={css.sectionGaqSubtitle}><FormattedMessage id="SectionFAQ.subtitle" /></p>
      <div>
        {Object.keys(faqs).length > 0 && Object.keys(faqs).map(key => (
          <div key={key} onClick={() => handleExpanded(key)}>
            <div className={css.questionWrapper}>
              <span className={css.question}>{key}</span>
              { expand === key ? (
                <ArrowDown className={css.icon} />
              ) : (
                <AddIcon className={css.icon} />
              )}
            </div>
            { expand === key ? (
              <div className={css.answerWrapper} onClick={e => e.stopPropagation() }>
                <p className={css.answer} dangerouslySetInnerHTML={{__html: faqs[key]}}></p>
              </div>
            ) : null }
          </div>
        ))}
      </div>
    </article>
  )
}

export default SectionFAQ;
