import React from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { FieldTextInput, Form } from '../../components';

import css from './TopbarKeywordsSearchForm.module.css';
import IconHourGlass from './IconHourGlass';

const TopbarKeywordsSearchForm = (props) => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        inputClassName,
        handleSubmit,
        isMobile,
        intl,
      } = fieldRenderProps;

      const SearchInputPlaceholder = intl.formatMessage({
        id: 'Topbar.SearchInputPlaceholder',
      });
      const classes = classNames(rootClassName || css.root, className);
      const inputClasses = classNames(css.inputClass, inputClassName);
      const id = isMobile ? "mobile" : "desktop";

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.desktopInputRoot}>
            <div className={isMobile ? css.mobileIcon : css.desktopIcon}>
              <IconHourGlass />
            </div>
            <FieldTextInput
              className={inputClasses}
              inputRootClass={isMobile ? css.mobileInput : css.desktopInput}
              type="text"
              id={`${id}-keywords-search`}
              name="searchText"
              placeholder={SearchInputPlaceholder}
            />
          </div>
        </Form>
      );
    }}
  />
);

export default TopbarKeywordsSearchForm;
