import React from 'react'

export default function AddIcon({className}) {
  return (
    <svg
      className={className}
      viewBox="0 0 42 42"
      x="0px"
      y="0px"
      id="svg-977a"
    >
      <polygon points="42,20 22,20 22,0 20,0 20,20 0,20 0,22 20,22 20,42 22,42 22,22 42,22 ">
      </polygon>
    </svg>
  )
}
