import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import css from './MonetizeCard.module.css';

class EaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(EaselyImage);

const Image = ({src, title}) => (
  <div className={css.iconWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.easelyImage} />
    </div>
  </div>
);

const MonetizeCard = ({item}) => {
  return (
    <div className={css.root}>
      <Image src={item.icon} alt="Tes"/>
      <h3 className={css.cardTitle}>
        <FormattedMessage id={item.id} />
      </h3>
      <p className={css.cardSubtitle}><FormattedMessage id={item.description} /></p>
    </div>
  )
}

export default MonetizeCard;
