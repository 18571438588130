import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import ScrollAnimation from 'react-animate-on-scroll';

import css from './SectionOurPurpose.module.css';
import { logos } from './images';
import NamedLink from '../NamedLink/NamedLink';

class TryEaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return (
      <div className={css.imageMargin}>
        <img alt={alt} {...rest} />
      </div>
    )
  }
}
const LazyImage = lazyLoadWithDimensions(TryEaselyImage);

const Image = ({src, title,  bgColor}) => (
  <div style={{background: bgColor}} className={css.imageWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.tryEaselyImage} />
    </div>
  </div>
);

const SectionOurPurpose = () => {
  return (
    <article className={css.root}>
      <div>
        <h1 className={css.title}>
          <FormattedMessage id="SustainabilityPage.SectionOurPurposeTitleHead" />
          <span className={css.highlightedByColor}>
            {" "}<FormattedMessage id="SustainabilityPage.SectionOurPurposeTitleTail" />
          </span>
        </h1>
        <h3 className={css.subtitle}>
          <FormattedMessage id="SustainabilityPage.SectionOurPurposeSubtitleHead" />
          <span className={css.highlightedByColor}>
            {" "}<FormattedMessage id="SustainabilityPage.SectionOurPurposeSubtitleTail" />
          </span>
        </h3>
        <p className={css.description}>
          <FormattedMessage id='SustainabilityPage.SectionOurPurposeDescriptionOne' />
        </p>
        <p className={css.description}>
          <FormattedMessage id='SustainabilityPage.SectionOurPurposeDescriptionTwo' />
        </p>
        <NamedLink name="SustainabilityPage" to={{hash: "contactus"}} className={css.link}>
          <button className={css.addProjectBtn}>
            <FormattedMessage id='SustainabilityPage.SectionOurPurposeButtonTitle' />
          </button>
        </NamedLink>
      </div>
      <div className={css.logoContainer}>
        {logos && logos.map(({src, title, bgColor}) => (
          <Image key={bgColor} src={src} title={title} bgColor={bgColor} />
        ))}
      </div>
    </article>
  )
}

export default SectionOurPurpose;
