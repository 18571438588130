import React from 'react';
import { required } from '../../../util/validators';
import { FieldSelect } from '../../../components';

import css from './CustomCategorySelectFieldMaybe.module.css';
import { FormattedMessage } from 'react-intl';

const CustomCategorySelectFieldMaybe = props => {
  const { name, id, categories, intl } = props;
  const listingType = props.listingType === "service" ? "servizio" : "prodotto"
  const asterisk = (
    <span className={css.asterisk}>*</span>
  );

  // First name
  const categoryLabel = (
    <FormattedMessage
      id='EditListingDescriptionForm.categoryLabel'
      values={{asterisk}}
    />
  );
  const categoryPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.categoryPlaceholder',
  }, {listingType});
  const categoryRequired = required(
    intl.formatMessage({
      id: 'EditListingDescriptionForm.categoryRequired',
    })
  );
  return categories ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={categoryLabel}
      validate={categoryRequired}
    >
      <option disabled value="">
        {categoryPlaceholder}
      </option>
      {categories.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomCategorySelectFieldMaybe;
