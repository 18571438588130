import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, ReviewRating } from '../../components';


import css from './LandingPageListingCard.module.css';
import LocationIcon from './LocationIcon';
import { propTypes } from '../../util/types';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });


const LandingPageListingCard = ({listing, intl}) => {
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData = undefined } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const serviceListing = publicData && publicData.type === "service";
  const address = publicData?.location?.address.split(',');
  const city = publicData?.location?.city || address && address[address.length-3];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const avgReviewRating = Math.round(publicData?.avg_review_rating) || 0

  const unitTranslationKey = serviceListing
    ? 'ListingCard.perGig' : 'ListingCard.perDay';

  return (
    <NamedLink
      className={css.root}
      name="ListingPage"
      params={{ id, slug }}
    >
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            // sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
          </div>
        </div>

        <div className={css.price}>
          <div
            className={css.priceValue}
            title={priceTitle}
          >
            {formattedPrice}/
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        <div className={css.reviewWrapper}>
          <ReviewRating
            rating={avgReviewRating}
            rootClassName={css.rootRviewClass}
            reviewStarClassName={css.reviewStarClass}
          />
        </div>
        <div className={css.locationWrapper}>
          <span className={css.location}>{city}</span>
          <LocationIcon className={css.locationIcon} />
        </div>

      </div>
    </NamedLink>
  )
};

LandingPageListingCard.defaultProps = {
  className: null,
  rootClassName: null,
};

LandingPageListingCard.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired
};

export default injectIntl(LandingPageListingCard);
