import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';

import {openAIGenerateListing} from '../../util/api';

import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldBoolean } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe/CustomCategorySelectFieldMaybe';

import css from './EditListingForm.module.css';
import config from '../../config';
import ManageAvailabilityCalendar from './ManageAvailabilityCalendar/ManageAvailabilityCalendar';
import RentPriceField from './RentPriceField/RentPriceField';
import SalePriceField from './SalePriceField/SalePriceField';
import AddImagesField from './AddImagesField/AddImagesField';
import LocationField from './LocationField/LocationField';
import TitleField from './TitleField/TitleField';
import DescriptionField from './DescriptionField/DescriptionField';
import VideoField from './VideoField/VideoField';
import InstagramField from './InstagramField/InstagramField';
import TiktokField from './TiktokField/TiktokField';
import FacebookField from './FacebookField/FacebookField';
import LinkedinField from './LinkedinField/LinkedinField';
import RentPriceFormSevenDaysPlusField from './RentPriceFormSevenDaysPlusField/RentPriceFormSevenDaysPlusField';
import RentPriceFromThirtyDaysPlusField from './RentPriceFromThirtyDaysPlusField/RentPriceFromThirtyDaysPlusField';
import ServiceNotAsProfessionalField from './ServiceNotAsProfessionalField/ServiceNotAsProfessionalField';

const LISTING_TYPE_SERVICE = 'service';

const EditListingForm = props => {
  const [submittedValues, setSubmittedValues] = useState({})
  const [imageUploadRequested, setImageUploadRequested] = useState(false)
  const [videoUploadRequested, setVideoUploadRequested] = useState(false)
  const [fetchingDescription, setFetchingDescription] = useState(false)

  return (
  <FinalForm
    {...props}
    mutators={{
      setField: ([fieldName, fieldVal], state, { changeValue }) => {
        changeValue(state, fieldName, () => fieldVal);
      }
    }}
    render={formRenderProps => {
      const {
        form,
        categories,
        className,
        disabled,
        pristine,
        ready,
        handleSubmit,
        onImageUpload,
        onVideoUpload,
        intl,
        invalid,
        saveActionMsg,
        updateInProgress,
        fetchErrors,
        values,
        availability,
        availabilityPlan,
        listingId,
        listingType,
        images,
        video,
        removedVideoIds,
        videoOrder,
        onRemoveImage,
        onRemoveVideo,
        isImageAdded,
        setIsImageAdded,
        isNewURI
      } = formRenderProps;

      const isService = listingType === LISTING_TYPE_SERVICE;
      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const videoArrayHasSameVideos = isEqual(removedVideoIds, videoOrder);
      const classes = classNames(css.root, className);
      const submittedOnce = Object.keys(submittedValues).length > 0;
      const uploadInProgress = imageUploadRequested || videoUploadRequested

      const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues);
      const submitInProgress = updateInProgress;

      const serviceNotAsProfessionalChecked = isNewURI && isService && values.serviceNotAsProfessional?.length === 0;
      const shouldDisabled = invalid || disabled || submitInProgress || uploadInProgress || pristine || pristineSinceLastSubmit;
      const submitDisabled = shouldDisabled || serviceNotAsProfessionalChecked;

      const submitTitle = async () => {
        const title = form.getFieldState('title').value
        if(title && fetchingDescription === false){
          setFetchingDescription(true)
          const res =  await openAIGenerateListing({
            title: title
          })
          setFetchingDescription(false)
          form.mutators.setField("description", res?.msg);
        }
      }

      const disableDescriptionBtn = () => {
        const title = form.getFieldState('title')
        return title && (title.value === undefined || title?.value.length < 3)
      }

      return (
        <Form
          className={classes}
          onSubmit={e => {
            setSubmittedValues(values)
            handleSubmit(e)
          }}
        >
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <div className={css.titleContainer}>
            <TitleField listingType={listingType} intl={intl} />
            <Button
              type="button"
              disabled={disableDescriptionBtn()}
              inProgress={fetchingDescription}
              spinnerClassName={css.spinner}
              onClick={submitTitle}
            >
              <FormattedMessage
                id="ListingPage.descriptionBtnLabel"
              />
            </Button>
          </div>
          <DescriptionField
            listingType={listingType}
            intl={intl}
          />
          <CustomCategorySelectFieldMaybe
            id="category"
            name="category"
            categories={categories}
            intl={intl}
            listingType={listingType}
          />
          <LocationField values={values} intl={intl} isService={isService} />
          <RentPriceField config={config} intl={intl} isService={isService} />
          {!isService && (
            <>
              <RentPriceFormSevenDaysPlusField config={config} />
              <RentPriceFromThirtyDaysPlusField config={config} />
              <SalePriceField intl={intl} config={config} />
            </>
          )}

          {isService && (
            <FieldBoolean
              className={css.isDeliverable}
              id={`${form}.isDeliverable`}
              name="isDeliverable"
              label="Servizio a domicilio"
              placeholder="Choose yes/no"
            />
          )}

          <VideoField
            intl={intl}
            form={form}
            video={video}
            onVideoUpload={onVideoUpload}
            onRemoveVideo={onRemoveVideo}
            videoUploading={videoUploadRequested}
            setVideoUploading={setVideoUploadRequested}
            images={images}
          />

          {isService && (
            <>
              <InstagramField intl={intl} />
              <TiktokField intl={intl} />
              <FacebookField intl={intl} />
              <LinkedinField intl={intl} />
            </>
          )}

          {!isNewURI && (
            <div
              id="manageavailability"
              className={css.manageavailability}
            >
              <ManageAvailabilityCalendar
                availability={availability}
                availabilityPlan={availabilityPlan}
                listingId={listingId}
              />
            </div>
          )}

          <AddImagesField
            images={images}
            intl={intl}
            form={form}
            fetchErrors={fetchErrors}
            onRemoveImage={onRemoveImage}
            onImageUpload={onImageUpload}
            isImageAdded={isImageAdded}
            setIsImageAdded={setIsImageAdded}
            imageUploadRequested={imageUploadRequested}
            setImageUploadRequested={setImageUploadRequested}
          />

          {isNewURI && isService ? (
            <ServiceNotAsProfessionalField intl={intl} />
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled && videoArrayHasSameVideos}
            ready={pristineSinceLastSubmit && videoArrayHasSameVideos}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
)};

EditListingForm.defaultProps = { className: null, fetchErrors: null};

EditListingForm.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingForm);
