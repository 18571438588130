import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import productionImage from './images/production.png';
import developmentImage from './images/development.png';


import css from './SectionGlobalChallenges.module.css';

class EaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(EaselyImage);

const Image = ({src, title}) => (
  <div className={css.imageWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.easelyImage} />
    </div>
  </div>
);

const SectionGlobalChallenges = () => {
  return (
    <article className={css.root}>
      <h1 className={css.sectionGlobalChallengesTitle}>
        <FormattedMessage id="SectionGlobalChallenges.titleHead" />
        <span className={css.highlightedByColor}>
          {" "}<FormattedMessage id="SectionGlobalChallenges.titleTail" />{" "}
        </span>
        <FormattedMessage id="SectionGlobalChallenges.titleJoinUs" />
      </h1>
      <h3 className={css.sectionGlobalChallengesSubtitle}>
        <FormattedMessage id="SectionGlobalChallenges.subtitle" />
      </h3>
      <div className={css.sectionGlobalChallengesContent}>
        <div className={css.sectionGlobalChallengesInfo}>
          <Image src={developmentImage} title="8 Decent work and economic growth" />
          <p className={css.subtitle}>
            <FormattedMessage id="SectionGlobalChallenges.developmentImageSubtitleHead" />
            <span className={css.highlightedByBold}>
              {" "}<FormattedMessage id="SectionGlobalChallenges.developmentImageSubtitleMiddle" />{" "}
            </span>
            <FormattedMessage id="SectionGlobalChallenges.developmentImageSubtitleTail" />
          </p>
        </div>
        <div className={css.sectionGlobalChallengesInfo}>
          <Image src={productionImage} title="12 Responsible Consumption and Production " />
          <p className={css.subtitle}>
            <FormattedMessage id="SectionGlobalChallenges.productionImageSubtitleHead" />
            <span className={css.highlightedByBold}>
              {" "}<FormattedMessage id="SectionGlobalChallenges.productionImageSubtitleHeadHighlighted" />{" "}
            </span>
            <FormattedMessage id="SectionGlobalChallenges.productionImageSubtitleTailOff" />
          </p>
        </div>
      </div>
    </article>
  )
}

export default SectionGlobalChallenges;
