import React, { useEffect, useRef, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedRedirect } from '../../components';
import css from './SectionHero.module.css';
import { userLocation } from '../../util/maps';
import config from '../../config';
import { locationBoundsMapbox } from '../LocationAutocompleteInput/GeocoderMapbox';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import logoImage from './easely-logo.png';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const [shouldNameRedirect, setShouldNameRedirect] = useState(false);
  const { rootClassName, className, history } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleOnClick = async () => {
    try {
      const latlng = await userLocation()
      const originMaybe = config.sortSearchByDistance ? { latlng } : {};
      const searchParams = {
        ...originMaybe,
        address: '',
        bounds: locationBoundsMapbox(latlng, config.maps.search.currentLocationBoundsDistance),
      };
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
    } catch (error) {
      setShouldNameRedirect(true);
    }
  }

  const classes = classNames(rootClassName || css.root, className);
  const heroSubtitle = classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted });

  const videoEl = useRef(null);

  const attemptPlay = () => {
    if(videoEl && videoEl.current) {
      videoEl?.current?.play().catch(error => {
        console.error("Error attempting to play", error);
      });
      videoEl.current.defaultMuted = true;
    }
  };

  useEffect(() => {
    attemptPlay();
  }, []);


  // if user not allows curent location then redirect to search page with `Italy` as static location
  if(shouldNameRedirect) {
    const redirectProps = {
      name: 'SearchPage',
      search: config.defaultMapSearchBounds,
    };

    return <NamedRedirect {...redirectProps} />
  }

  return (
    <div className={classes}>
      <video loop
             autoPlay
             muted
             playsInline
             className={css.landingVideo}
      >
        <source src={require('../../assets/landing.mp4')} type="video/mp4" />
      </video>
      <div className={css.heroContent}>
        <div className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <img src={logoImage} alt="Easely Logo" />
        </div>
        <h2 className={heroSubtitle}>
          <FormattedMessage id="SectionHero.reinventing" />
        </h2>
        <button
          onClick={handleOnClick}
          type="button"
          className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </button>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
