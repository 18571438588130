import React from 'react';
import { FieldSelect } from '../../../components';
import css from './RentPriceFromThirtyDaysPlusField.module.css';

const RentPriceFromThirtyDaysPlusField = ({ config }) => {
  const { discountChoices } = config.custom;

  return discountChoices ? (
    <FieldSelect
      className={css.input}
      name='thirtyDaysPlus'
      id='thirtyDaysPlus'
      label={'Sconto per noleggi 30+ giorni'}
    >
      <option value="">
        {'-'}
      </option>
      {discountChoices.map(d => (
        <option key={d.key} value={d.key}>
          {d.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
}

export default RentPriceFromThirtyDaysPlusField;
