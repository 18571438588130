import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Arrow } from "@egjs/flicking-plugins";

// import styles
import "../../assets/flicking.css"; // Supports IE10+, using CSS flex: ;
// Or...
import "../../assets/flicking-inline.css"; //Supports IE9+, using CSS inline-box
import "../../assets/arrow.css";

import LandingPageListingCard from '../LandingPageListingCard/LandingPageListingCard';
import css from './SectionRecentyAddedProducts.module.css';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';

const SectionRecentyAddedProducts = ({listings, intl}) => {
  const plugins = [ new Arrow() ];
  const pluginsProp = {plugins}

  const flickingProps = {
    viewportTag: "div",
    cameraTag: "div",
    cameraClass: "",
    renderOnSameKey: false,
    align: "center",
    onMove: (e) => {},
    onWillChange: (e) => {},
    horizontal: true,
    circular: true,
    hideBeforeInit: true,
    firstpanelsize: "100%",
    ...pluginsProp,
  }

  const arrowPrevClasses = classNames('flicking-arrow-prev', css.arrow)
  const arrowNextClasses = classNames('flicking-arrow-next', css.arrow)

  return (
    <article className={css.root}>
      <div>
        <h1 className={css.title}>
          <span className={css.highlightedByColor}>
            {" "}<FormattedMessage id="SectionRecentyAddedProducts.titleHead" />{" "}
          </span>
          <FormattedMessage id="SectionRecentyAddedProducts.titleTail" />
        </h1>
      </div>
      <Flicking {...flickingProps}>
        {listings.map((listing) => (
          <div className={css.flickingPanel} key={listing.id.uuid}>
            <LandingPageListingCard
              listing={listing}
              intl={intl}
            />
          </div>
        ))}

        <ViewportSlot>
          <span className={arrowPrevClasses}>
            <ArrowLeft />
          </span>
          <span className={arrowNextClasses}>
            <ArrowRight />
          </span>
        </ViewportSlot>
      </Flicking>
    </article>
  )
}

export default SectionRecentyAddedProducts;
