import React from 'react'
import { FieldCurrencyInput } from '../../../components'
import * as validators from '../../../util/validators';
import { formatMoney } from '../../../util/currency';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from './RentPriceField.module.css';

const { Money } = sdkTypes;

const RentPriceField = ({ config, intl, isService }) => {
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const gigOrDay = isService ? "prestazione" : "giorno"

  const translationKey = isNightly
    ? 'EditListingPricingForm.pricePerNight'
    : isDaily
    ? 'EditListingPricingForm.pricePerDay'
    : 'EditListingPricingForm.pricePerUnit';

  const asterisk = (
    <span className={css.asterisk}>*</span>
  );

  const pricePerUnitMessage = intl.formatMessage({
    id: !isService ? 'EditListingPricingForm.priceInputLabel' : 'EditListingPricingForm.priceInputLabelService',
  }, {asterisk});

  const pricePlaceholderMessage = intl.formatMessage({
    id: 'EditListingPricingForm.priceInputPlaceholder',
  }, {gigOrDay});

  const priceRequired = validators.required(
    intl.formatMessage({
      id: 'EditListingPricingForm.priceRequired',
    })
  );
  const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    intl.formatMessage(
      {
        id: 'EditListingPricingForm.priceTooLow',
      },
      {
        minPrice: formatMoney(intl, minPrice),
      }
    ),
    config.listingMinimumPriceSubUnits
  );
  const priceValidators = config.listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;

  return (
    <FieldCurrencyInput
      id="price"
      name="price"
      className={css.priceInput}
      label={pricePerUnitMessage}
      placeholder={pricePlaceholderMessage}
      currencyConfig={config.currencyConfig}
      validate={priceValidators}
    />
  )
}

export default RentPriceField
