import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { FieldTextInput, Form, PrimaryButton } from '../../components';

import css from './ContactUsForm.module.css';
import { FormattedMessage } from 'react-intl';
import * as validators from '../../util/validators';

const ContactUsForm = (props) => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        inputClassName,
        handleSubmit,
        inProgress,
        invalid,
        disabled,
        pristine,
        ok,
        intl,
      } = fieldRenderProps;

      const asterisk = (
        <span className={css.asterisk}>*</span>
      );

      const emailInputLabel = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormEmail',
      }, {asterisk});
      const emailInputPlaceholder = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormEmailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormEmailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);

      const emailInvalidMessage = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormEmailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const contactPersonLabel = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormContactPerson',
      }, {asterisk});
      const contactPersonPlaceholder = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormContactPersonPlaceholder',
      });
      const contactPersonRequiredMessage = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormContactPersonRequired',
      });
      const contactPersonRequired = validators.required(contactPersonRequiredMessage);

      const messageLabel = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormMessage',
      }, {asterisk});
      const messagePlaceholder = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormMessagePlaceholder',
      });
      const messageRequiredMessage = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormMessageRequired',
      });
      const messageRequired = validators.required(messageRequiredMessage);

      const companyNameLabel = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormCompanyName',
      });
      const companyNamePlaceholder = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormCompanyNamePlaceholder',
      });

      const phoneNumberLabel = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormPhoneNumber',
      });
      const phoneNumberPlaceholder = intl.formatMessage({
        id: 'SustainabilityPage.ContactUsFormPhoneNumberPlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);
      const inputClasses = classNames(css.inputClass, inputClassName);
      const formId = 'contactUsForm';
      const submitDisabled = invalid || inProgress || disabled || ok

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.inputWrapper}>
            <FieldTextInput
              className={css.input}
              type="email"
              name="email"
              id={formId ? `${formId}.email` : 'email'}
              label={emailInputLabel}
              placeholder={emailInputPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
              className={css.input}
              type="text"
              name="contactPerson"
              id={formId ? `${formId}.contactPerson` : 'contactPerson'}
              label={contactPersonLabel}
              placeholder={contactPersonPlaceholder}
              validate={contactPersonRequired}
            />
          </div>
          <div className={css.inputWrapper}>
            <FieldTextInput
              type="text"
              className={css.input}
              name="company"
              id={formId ? `${formId}.company` : 'company'}
              label={companyNameLabel}
              placeholder={companyNamePlaceholder}
            />
            <FieldTextInput
              type="text"
              className={css.input}
              name="phoneNumber"
              id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
              label={phoneNumberLabel}
              placeholder={phoneNumberPlaceholder}
            />
          </div>
          <FieldTextInput
            className={css.messageInput}
            type="textarea"
            rows={3}
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />

          <div className={css.bottomWrapper}>
            <PrimaryButton
              type="submit"
              className={css.contactUsFormBtn}
              inProgress={inProgress}
              ready={ok}
              disabled={submitDisabled}
            >
              <FormattedMessage id="SustainabilityPage.ContactUsFormSubmitButtonTitle" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

export default ContactUsForm;
