import waterLogo from './water.png';
import w4wLogo from './w4w.png';
import rainforestLogo from './rainforest.png';
import childrenLogo from './children.png';
import fondazioneLogo from './fondazione.png';
import food4lifeLogo from './food4life.png';

 export const logos = [
  {
    bgColor: '#26bde2',
    src: waterLogo,
    title: 'Water'
  },
  {
    bgColor: '#3f7e44',
    src: rainforestLogo,
    title: 'Rainforest Foundation US'
  },
  {
    bgColor: '#dd1367',
    src: fondazioneLogo,
    title: 'Fondazione Umberto Veronesi'
  },
  {
    bgColor: '#e5243b',
    src: childrenLogo,
    title: 'Save the Children'
  },
  {
    bgColor: '#dda63a',
    src: food4lifeLogo,
    title: 'Food for Life'
  },
  {
    bgColor: '#ff3a21',
    src: w4wLogo,
    title: 'Women for Women International'
  },
];
