import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import ScrollAnimation from 'react-animate-on-scroll';

import angiLogo from './images/angi.png';
import kilometroLogo from './images/kilometro.png';
import politecnicoLogo from './images/politecnico.png';
import pweLogo from './images/pwe.png';
import tagLogo from './images/tag.png';




import css from './SectionPartners.module.css';


class TryEaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return (
      <div className={css.imageMargin}>
        <img alt={alt} {...rest} />
      </div>
    )
  }
}
const LazyImage = lazyLoadWithDimensions(TryEaselyImage);

const Image = ({src, title}) => (
  <div className={css.imageWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.tryEaselyImage} />
    </div>
  </div>
);

const SectionPartners = () => {
  return (
    <article className={css.root}>
      <div>
        <h1 className={css.title}>
          <FormattedMessage id="SectionPartners.titleHead" />
          <span className={css.highlightedByColor}>
            {" "}<FormattedMessage id="SectionPartners.titleMiddle" />{" "}
          </span>
        </h1>
      </div>
      <div className={css.partnersLogoWrapper}>
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={angiLogo} title="ANGI Logo" />
        </ScrollAnimation>
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={politecnicoLogo} title="Politecnico Logo" />
        </ScrollAnimation>
        {/*<ScrollAnimation animateIn={css.lightSpeedIn}>*/}
        {/*  <Image src={kilometroLogo} title="Kilometro Logo" />*/}
        {/*</ScrollAnimation>*/}
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={tagLogo} title="Tag Logo" />
        </ScrollAnimation>
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={pweLogo} title="PWE Logo" />
        </ScrollAnimation>
      </div>
    </article>
  )
}

export default SectionPartners;
