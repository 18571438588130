import React from 'react';
import { FieldRadioButton } from '../../../components';
import { intlShape } from '../../../util/reactIntl';

import css from './GenderSelectionField.module.css';

const GenderSelectionField = ({intl}) => {
  const id = 'genderSelectionField';
  const genderLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.genderLabel',
  });

  const femaleLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.femaleLabel',
  });
  const maleLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.maleLabel',
  });
  const otherLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.otherLabel',
  });

  return (
    <div className={css.genderInput}>
      <h4 className={css.genderLabel}>{genderLabel}</h4>
      <FieldRadioButton
        id={`${id}-option-id1`}
        name="gender"
        label={femaleLabel}
        value='female'
        showAsRequired={true}
      />
      <FieldRadioButton
        id={`${id}-option-id2`}
        name="gender"
        label={maleLabel}
        value='male'
        showAsRequired={true}
      />
      <FieldRadioButton
        id={`${id}-option-id3`}
        name="gender"
        label={otherLabel}
        value='other'
        showAsRequired={true}
      />
    </div>
  )
};

GenderSelectionField.propTypes = {
  intl: intlShape.isRequired,
};

export default GenderSelectionField;
