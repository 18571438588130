import React from 'react'
import { FieldCurrencyInput } from '../../../components'
import * as validators from '../../../util/validators';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from './SalePriceField.module.css';

const { Money } = sdkTypes;

const SalePriceField = ({ intl, config }) => {
  const salePricePerUnitMessage = intl.formatMessage({
    id: 'EditListingPricingForm.salePriceInputLabel',
  });

  const salePricePlaceholderMessage = intl.formatMessage({
    id: 'EditListingPricingForm.salePriceInputPlaceholder',
  });

  const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    intl.formatMessage(
      {
        id: 'EditListingPricingForm.priceTooLow',
      },
      {
        minPrice: formatMoney(intl, minPrice),
      }
    ),
    config.listingMinimumPriceSubUnits
  );
  const priceValidators = config.listingMinimumPriceSubUnits &&
   validators.required(minPriceRequired)

  return (
    <FieldCurrencyInput
      id="salePrice"
      name="salePrice"
      className={css.salePrice}
      label={salePricePerUnitMessage}
      placeholder={salePricePlaceholderMessage}
      currencyConfig={config.currencyConfig}
      validate={priceValidators}
    />
  )
}

export default SalePriceField
