import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import productIcon from './product.png';
import serviceIcon from './service.png';


import css from './SectionGiveValueToItems.module.css';
import NamedLink from '../NamedLink/NamedLink';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const PRODUCT = 'product';
const SERVICE = 'service';

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

class EaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(EaselyImage);

const Image = ({src, title}) => (
  <div className={css.iconWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.easelyImage} />
    </div>
  </div>
);

const SectionGiveValueToItems = ({history}) => {
  const redirectToSearchPage = (key) => {
    const searchParams = {
      pub_type: key
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  return (
    <article className={css.root}>
      <h1 className={css.sectionHowWeCreateValuesTitle}>
        <FormattedMessage id="SectionGiveValueToItems.titleHead" />
        <span className={css.higlightedByColor}>
          {" "}<FormattedMessage id="SectionGiveValueToItems.titleTail" />
        </span>...
      </h1>
      <div className={css.sectionHowWeCreateValuesConetnt}>
        <div className={css.sectionHowWeCreateValuesConetntBox}>
          <Image src={productIcon} />
          <h2 className={css.boxTitle}>
            <span className={css.higlightedByColor}>
             <FormattedMessage id="SectionGiveValueToItems.searchItemHead" />{" "}
            </span>
            <FormattedMessage id="SectionGiveValueToItems.searchItemTail" />
          </h2>
          <p className={css.easelySubtitle}>
            <FormattedMessage id="SectionGiveValueToItems.searchItemDescription" />
          </p>
          <div className={css.boxBtnWrapper}>
            <button
              onClick={() => redirectToSearchPage(PRODUCT)}
              className={css.boxBtn}
            >
              <FormattedMessage id="SectionGiveValueToItems.searchButtonTitle" />
            </button>
          </div>
        </div>
        <div className={css.sectionHowWeCreateValuesConetntBox}>
          <Image src={serviceIcon} />
          <h2 className={css.boxTitle}>
            <span className={css.higlightedByColor}>
             <FormattedMessage id="SectionGiveValueToItems.listItemHead" />{" "}
            </span>
            <FormattedMessage id="SectionGiveValueToItems.listItemTail" />
          </h2>
          <p className={css.easelySubtitle}>
            <FormattedMessage id="SectionGiveValueToItems.listItemDescription" />
          </p>
          <div className={css.boxBtnWrapper}>
            <NamedLink
              className={css.link}
              name="EditListingPage"
              params={{ slug: draftSlug, id: draftId, type: 'new', tab: PRODUCT }}
            >
              <button className={css.boxBtn}>
                <FormattedMessage id="SectionGiveValueToItems.listButtonTitle" />
              </button>
            </NamedLink>
          </div>
        </div>
      </div>
    </article>
  )
}

export default SectionGiveValueToItems;
