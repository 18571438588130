import React from 'react';
import { string, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import {
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';
import FacebookIcon from './icons/FacebookIcon';
import TwitterIcon from './icons/TwitterIcon';
import InstagramIcon from './icons/InstagramIcon';
import LinkedinIcon from './icons/LinkedinIcon';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteILinkedinPage } = config;

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <FacebookIcon />
    </ExternalLink>
  ) : null;


  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <InstagramIcon />
    </ExternalLink>
  ) : null;

  const linkedinLink = siteInstagramPage ? (
    <ExternalLink
      key="linkedinLink"
      href={siteILinkedinPage}
      className={css.icon}
      title={goToInsta}
    >
      <LinkedinIcon />
    </ExternalLink>
  ) : null;

  return [fbLink, instragramLink, linkedinLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <ul className={css.list}>
              <li className={css.listItem}>
                <NamedLink name="HowItWorksPage" className={css.link}>
                  <FormattedMessage id="Footer.toHowitWorks" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="SustainabilityPage" className={css.link}>
                  <FormattedMessage id="Footer.toOurImpact" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="HowItWorksPage" to={{hash: "faqs"}} className={css.link}>
                  <FormattedMessage id="Footer.toFAQPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="TermsOfServicePage" className={css.link}>
                  <FormattedMessage id="Footer.termsOfUse" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="PrivacyPolicyPage" className={css.link}>
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="CookiePolicyPage" className={css.link}>
                  <FormattedMessage id="Footer.cookiePolicy" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <Link
                  to='#'
                  onClick={(e) => {
                    e.preventDefault();
                    if(typeof window !== 'undefined') {
                      window.location.href = "mailto:info@easely.it";
                    }
                  }}
                  className={css.link}
                >
                  info@easely.it
                </Link>
              </li>
            </ul>
          </div>

          <div className={css.organization} id="organization">
            <NamedLink name="LandingPage" className={css.logoLink}>
              <Logo format="desktop" className={css.logo} />
            </NamedLink>
            <div className={css.socialIcons}>{socialMediaLinks}</div>
          </div>

          <p className={css.organizationCopyright}>
            <FormattedMessage id="Footer.copyright" />
          </p>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
