import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FieldTextInput, Form, PrimaryButton } from '../../components';

import css from './DeleteMyAccountForm.module.css';
import { FormattedMessage } from 'react-intl';
import * as validators from '../../util/validators';

const DeleteMyAccountForm = (props) => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        inputClassName,
        handleSubmit,
        inProgress,
        deleteMyAccountError,
        invalid,
        disabled,
        pristine,
        ok,
        intl,
      } = fieldRenderProps;

      const asterisk = (
        <span className={css.asterisk}>*</span>
      );

      const submitError = deleteMyAccountError ? (
        <div className={css.error}>
          <FormattedMessage
            id={deleteMyAccountError.status === 409 ?
              "ProfileSettingsForm.deleteMyAccountConflict"
              :
              "ProfileSettingsForm.deleteMyAccountFailed"
            }
            values={{ errorCause: deleteMyAccountError?.message }}
          />
        </div>
      ) : null;

      // password
      const passwordLabel = intl.formatMessage({
        id: 'ProfileSettingsPage.passwordLabel',
      }, { asterisk });
      const passwordPlaceholder = intl.formatMessage({
        id: 'ProfileSettingsPage.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'ProfileSettingsPage.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);


      const classes = classNames(rootClassName || css.root, className);
      const inputClasses = classNames(css.inputClass, inputClassName);
      const formId = 'deleteMyAccountForm';
      const submitDisabled = invalid || inProgress || disabled || ok || pristine

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.password}
            type="password"
            id={formId ? `${formId}.password` : 'password'}
            name="password"
            autoComplete="current-password"
            label={passwordLabel}
            placeholder={passwordPlaceholder}
            validate={passwordRequired}
          />
          {submitError}
          <PrimaryButton
            type="submit"
            className={css.deleteSubmitBtn}
            inProgress={inProgress}
            ready={ok}
            disabled={submitDisabled}
          >
            <FormattedMessage id="ProfileSettingsPage.deleteButtonTittle" />
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

export default DeleteMyAccountForm;
