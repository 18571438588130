import React from 'react'
import { FieldTextInput } from '../../../components';
import css from './FacebookField.module.css';

const FacebookField = ({intl}) => {
  const facebookMessage = intl.formatMessage({ id: 'EditListing.facebook' });
  const facebookPlaceholderMessage = intl.formatMessage({
    id: 'EditListing.facebookPlaceholder',
  });

  return (
    <FieldTextInput
      id="facebook"
      name="facebook"
      className={css.facebook}
      type="text"
      label={facebookMessage}
      placeholder={facebookPlaceholderMessage}
    />
  )
}

export default FacebookField;
