import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing, ensureOwnListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';


import css from './EditListingWizard.module.css';
import EditListingForm from '../../forms/EditListingForm/EditListingForm';
import config from '../../config';
import { findOptionsForCategory } from '../../util/search';
import ListingLink from '../ListingLink/ListingLink';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getRandomNumber } from '../../util/getRandomNumber';

const { Money } = sdkTypes

// EditListingWizardForm component supports these tabs
export const SUPPORTED_TABS = [
  'product',
  'service',
];

const SEVEN_DAYS_PLUS = 'sevenDaysPlus'
const THIRTY_DAYS_PLUS = 'thirtyDaysPlus'

const defaultAvailabilityPlan = isServiceListing => {
  const seats = isServiceListing ? 7 : 1;
  return {
    type: 'availability-plan/day',
    entries: [
      { dayOfWeek: 'mon', seats },
      { dayOfWeek: 'tue', seats },
      { dayOfWeek: 'wed', seats },
      { dayOfWeek: 'thu', seats },
      { dayOfWeek: 'fri', seats },
      { dayOfWeek: 'sat', seats },
      { dayOfWeek: 'sun', seats },
    ],
  }
};

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;


const EditListingWizardForm = props => {
  const {
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    location: hashQueryInUrl,
    images,
    video,
    removedVideoIds,
    videoOrder,
    availability,
    listing,
    handlePublishListing,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onVideoUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onRemoveVideo,
    onChange,
    updateInProgress,
    intl,
  } = props;

  const { type, tab } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;
  const ensureCurrentListing = ensureListing(listing);
  const currentListing = ensureOwnListing(ensureCurrentListing);
  const { description, title, price, publicData } = currentListing.attributes;

  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const getLocationInitialValues = () => {
    const { geolocation, publicData } = currentListing?.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO: bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, building, city } = location;

    return {
      building,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation, city },
          }
        : null,
    };
  }

  const [selectedListingType, setSelectedListingType] = useState(tab);
  const isServiceListing = selectedListingType === "service";

  const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan(isServiceListing);
  const productCategoryOptions = findOptionsForCategory('category', 'product', config.custom.filters);
  const serviceCategoryOptions = findOptionsForCategory('category', 'service', config.custom.filters);
  const { building, location } = getLocationInitialValues()
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const salePrice = publicData.salePrice ? new Money(publicData.salePrice[0], publicData.salePrice[1]) : null
  const discounts = publicData.discounts ? publicData.discounts : {}

  const [updateImages, setUpdatedImages] = useState(images);
  const [isImageAdded, setIsImageAdded] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: title || "",
    description: description || "",
    price: price,
    salePrice: salePrice,
    instagram: publicData.instagram || "",
    tiktok: publicData.tiktok || "",
    facebook : publicData.facebook  || "",
    linkedin : publicData.linkedin  || "",
    isDeliverable: publicData.isDeliverable || false,
    availabilityPlan: availabilityPlan,
    category: publicData.category || "" ,
    sevenDaysPlus: discounts[SEVEN_DAYS_PLUS]?.percent || null,
    thirtyDaysPlus: discounts[THIRTY_DAYS_PLUS]?.percent || null,
    building,
    location,
    images,
    video,
  });

  const handleSelectedListingTyped = t => {
    setSelectedListingType(t)
    const currentPathParams = {
      ...params,
      tab: t
    };
    const routes = routeConfiguration();

    if(params.tab !== t){
      const to = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
      history.replace(to);
    }
  }

  const onCompleteEditListingWizardForm = (updateValues) => {
    const { currentUser, stripeAccount, setShowPayoutDetails } = props;
    const stripeConnected =
      currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    if (!stripeConnected || requirementsMissing){
      setShowPayoutDetails();
      return;
    }
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};

    // check atlest one image is added
    const { images } = imageProperty;
    if(!images.length > 0) {
      setIsImageAdded(true);
      return;
    }

    const updateValuesWithImages = { ...otherValues, images };
    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      onUpsertListingDraft(upsertValues)
        .then(res => {
          const { id } = res.data.data;
          handlePublishListing(id);
        })
        .catch(e => {
          // No need for extra actions
        });
    } else {
      onUpdateListing({ ...updateValuesWithImages, id: currentListing.id });
    }
  };

  // const classes = classNames(rootClassName || css.root, className);
  const formTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage
      id="EditListingDescriptionPanel.createListingTitle"
      values={{ title }}
    />
  )

  const submitButtonTranslationKey = isNewURI
    ? "EditListingWizard.saveNewListing"
    : "EditListingWizard.editListing"
  const categories = selectedListingType === "product" ? productCategoryOptions : serviceCategoryOptions

  const cb = useCallback(() => {
    setInitialValues({
      ...initialValues,
      title,
      description,
      price,
      salePrice: salePrice,
      category: publicData.category,
      instagram: publicData.instagram,
      tiktok: publicData.tiktok,
      facebook : publicData.facebook,
      linkedin : publicData.linkedin,
      isDeliverable: publicData.isDeliverable,
      sevenDaysPlus: discounts[SEVEN_DAYS_PLUS]?.percent,
      thirtyDaysPlus: discounts[THIRTY_DAYS_PLUS]?.percent,
      availabilityPlan,
      images,
      video,
      building,
      location
    })
  }, [title, description, discounts, price, publicData, availabilityPlan, images, video, building, location])

  useEffect(() => {
    cb()
  }, []);

  useEffect(() => {
    setUpdatedImages(images)
  }, [images]);

  const findPosition = (obj) => {
   let currenttop = 0;
   if(obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop;
    }
  }

  useEffect(()=> {
    if (hashQueryInUrl.hash) {
      const elem = document.getElementById(hashQueryInUrl.hash.slice(1))
      if (elem) {
        window.scrollTo(0, findPosition(elem));
      }
    }
  }, []);

  return (
    <div className={css.editListingContainer}>
      {isNewURI ? (
        <div className={css.toggleBtnsWrapper}>
          <div className={css.toggleBtnsContainer}>
            {SUPPORTED_TABS.map(listingType => (
              <button
                key={listingType}
                type="button"
                className={listingType === selectedListingType ? css.btnSelected : css.btn}
                onClick={() => handleSelectedListingTyped(listingType)}
              >
                Aggiungi un {listingType === "product" ? "prodotto" : "servizio"}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <h1>{formTitle}</h1>
      )}

      <EditListingForm
        className={css.form}
        initialValues={initialValues}
        listingId={currentListing.id}
        availability={availability}
        availabilityPlan={availabilityPlan}
        saveActionMsg={intl.formatMessage({ id: submitButtonTranslationKey })}
        listingType={selectedListingType}

        onSubmit={values => {
          const {
            title,
            description,
            price,
            salePrice,
            category,
            building = '',
            location,
            isDeliverable,
            instagram,
            tiktok,
            facebook,
            linkedin,
            serviceNotAsProfessional,
          } = values;
          const { selectedPlace: { address, origin, city } } = location;
          const processSalePrice = salePrice !== null ?
          [salePrice.amount, salePrice.currency]: null;

          const sevenDaysPlusDiscount = values.sevenDaysPlus !== undefined;
          const thirtyDaysPlusDiscount = values.thirtyDaysPlus !== undefined;
          const hasDiscount = sevenDaysPlusDiscount|| thirtyDaysPlusDiscount
          const sevenDaysPlus = sevenDaysPlusDiscount ? {
            sevenDaysPlus: {
              percent: parseInt(values.sevenDaysPlus),
              type: "percentage"
            }
          } : {}
          const thirtyDaysPlus = thirtyDaysPlusDiscount ? {
            thirtyDaysPlus: {
              percent: parseInt(values.thirtyDaysPlus),
              type: "percentage"
            }
          } : {}
          const discountsObj = hasDiscount ? {...sevenDaysPlus, ...thirtyDaysPlus} : null;
          const defaultReviewRating = getRandomNumber(3, 5);

          const privateData = serviceNotAsProfessional ? {
            serviceNotAsProfessional: values?.serviceNotAsProfessional[0] === 'true' ? true : null,
          } : {};

          const updateValues = {
            title: title.trim(),
            description,
            price,
            availabilityPlan,
            geolocation: origin,
            images: updateImages,
            publicData: {
              category,
              video: video,
              instagram,
              tiktok,
              facebook,
              linkedin,
              salePrice: processSalePrice,
              isDeliverable,
              type: selectedListingType,
              avg_review_rating: publicData.avg_review_rating || defaultReviewRating,
              location: { address, building, city },
              discounts: discountsObj,
            },
            privateData: { ...privateData },
          };
          onCompleteEditListingWizardForm(updateValues);
        }}
        onChange={onChange}
        disabled={fetchInProgress}
        ready={newListingPublished}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categories}
        images={images}
        video={video}
        removedVideoIds={removedVideoIds}
        videoOrder={videoOrder}
        onImageUpload={onImageUpload}
        onVideoUpload={onVideoUpload}
        onUpdateImageOrder={onUpdateImageOrder}
        onRemoveImage={onRemoveImage}
        onRemoveVideo={onRemoveVideo}
        isImageAdded={isImageAdded}
        setIsImageAdded={setIsImageAdded}
        isNewURI={isNewURI}
      />
    </div>
  )
};

EditListingWizardForm.defaultProps = {
  listing: null,
};

const { array, bool, func, object, oneOf, shape, string } = PropTypes;

EditListingWizardForm.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  availability: object.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onVideoUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onRemoveVideo: func.isRequired,
  onChange: func.isRequired,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardForm;
