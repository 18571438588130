import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import productIcon from './product.png';
import serviceIcon from './service.png';

import css from './SectionHowWeCreateValues.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const PRODUCT = 'product';
const SERVICE = 'service';

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

class EaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(EaselyImage);

const Image = ({src, title}) => (
  <div className={css.iconWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.easelyImage} />
    </div>
  </div>
);

const SectionHowWeCreateValues = ({history}) => {
  const redirectToSearchPage = (key) => {
    const searchParams = {
      pub_type: key
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  return (
    <article className={css.root}>
      <h1 className={css.sectionHowWeCreateValuesTitle}>
        <FormattedMessage id="SectionHowWeCreateValues.titleHead" />
        <span className={css.higlightedByColor}>
          {" "}<FormattedMessage id="SectionHowWeCreateValues.titleMiddle" />{" "}
        </span>
        <FormattedMessage id="SectionHowWeCreateValues.titleTail" />
      </h1>
      <div className={css.sectionHowWeCreateValuesConetnt}>
        <div className={css.sectionHowWeCreateValuesConetntBox}>
          <Image src={productIcon} />
          <h2 className={css.boxTitle}>
            <FormattedMessage id="SectionHowWeCreateValues.products" />
          </h2>
          <p className={css.easelySubtitle}>
            <FormattedMessage id="SectionHowWeCreateValues.productsDescription" />
          </p>
          <div className={css.boxBtnWrapper}>
            <button onClick={() => redirectToSearchPage(PRODUCT)} className={css.boxBtn}>
              <FormattedMessage id="SectionHowWeCreateValues.SearchButtonTitle" />
            </button>
            <NamedLink
              className={css.link}
              name="EditListingPage"
              params={{ slug: draftSlug, id: draftId, type: 'new', tab: PRODUCT }}
            >
              <button className={css.boxBtn}>
                <FormattedMessage id="SectionHowWeCreateValues.ListButtonTitle" />
              </button>
            </NamedLink>
          </div>
        </div>
        <div className={css.sectionHowWeCreateValuesConetntBox}>
          <Image src={serviceIcon} />
          <h2 className={css.boxTitle}>
            <FormattedMessage id="SectionHowWeCreateValues.services" />
          </h2>
          <p className={css.easelySubtitle}>
            <FormattedMessage id="SectionHowWeCreateValues.servicesDescription" />
          </p>
          <div className={css.boxBtnWrapper}>
            <button onClick={() => redirectToSearchPage(SERVICE)} className={css.boxBtn}>
              <FormattedMessage id="SectionHowWeCreateValues.SearchButtonTitle" />
            </button>
            <NamedLink
              className={css.link}
              name="EditListingPage"
              params={{ slug: draftSlug, id: draftId, type: 'new', tab: SERVICE }}
            >
              <button className={css.boxBtn}>
                <FormattedMessage id="SectionHowWeCreateValues.ListButtonTitle" />
              </button>
            </NamedLink>
          </div>
        </div>
      </div>
    </article>
  )
}

export default SectionHowWeCreateValues;
