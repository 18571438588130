import React from 'react';
import { required, composeValidators } from '../../../util/validators';
import { FieldCheckbox } from '../../../components';
import css from './ServiceNotAsProfessionalField.module.css';

const ServiceNotAsProfessionalField = ({ intl }) => {
  const asterisk = (
    <span className={css.asterisk}>*</span>
  );

  const label = intl.formatMessage({
    id: 'EditListingServiceNotAsProfessional.label',
  }, { asterisk });

  const requiredMessage = intl.formatMessage({
    id: 'EditListingServiceNotAsProfessional.required',
  });

  return (
    <FieldCheckbox
      className={css.fieldCheckbox}
      id="serviceNotAsProfessional"
      name="serviceNotAsProfessional"
      label={label}
      value="true"
      validate={composeValidators(required(requiredMessage))}
    />
  )
}

export default ServiceNotAsProfessionalField
