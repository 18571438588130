import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div className={css.lastUpdated}>Ultimo aggiornamento: 24 Aprile 2023</div>
      <div>
        Lo scopo del presente documento è di informare la persona fisica (di seguito “Interessato”) relativamente al trattamento dei suoi dati personali (di seguito “Dati Personali”) raccolti dal titolare del trattamento, Easely Srl, con sede legale in via Bernardo Quaranta, 45 20139 Milano, indirizzo e-mail info@easely.it, (di seguito “Titolare”), tramite e www.easely.it (di seguito “Applicazione”).
      </div>
      <div>
        Le modifiche e gli aggiornamenti saranno vincolanti non appena pubblicati sull’Applicazione. In caso di mancata accettazione delle modifiche apportate all’Informativa Privacy, l’Interessato è tenuto a cessare l’utilizzo di questa Applicazione e può richiedere al Titolare di cancellare i propri Dati Personali.
      </div>
      <h2>1. Categorie di Dati Personali trattati</h2>
      <div>
        Il Titolare tratta le seguenti tipologie di Dati Personali forniti volontariamente dall’Interessato:
      </div>
      <div>
        <ul>
          <li>Dati di contatto: nome, cognome, indirizzo, e-mail, telefono, immagini, credenziali di autenticazione, eventuali ulteriori informazioni inviate dall'Interessato, etc.</li>
          <li>Dati fiscali e di pagamento: codice fiscale, partita IVA, dati della carta di credito, estremi del conto corrente bancario, etc.</li>
          <li>Dati relativi al rapporto di lavoro: dati inseriti nel curriculum vitae, dati relativi al coniuge o ai figli, dati previdenziali, etc.</li>
        </ul>
      </div>
      <div>
        Il Titolare tratta le seguenti tipologie di Dati Personali raccolti in maniera automatizzata:
      </div>
      <div>
        <ul>
          <li>Dati tecnici: Dati Personali prodotti dai dispositivi, dalle applicazioni, dagli strumenti e dai protocolli utilizzati, quali ad esempio, informazioni sul dispositivo utilizzato, indirizzi IP, tipo di browser, tipo di provider Internet (ISP). Tali Dati Personali possono lasciare tracce che, in particolare se combinate con identificativi univoci e altre informazioni ricevute dai server, possono essere utilizzate per creare profili delle persone fisiche
          </li>
          <li>Dati di navigazione e di utilizzo dell’Applicazione: quali, ad esempio, pagine visitate, numero di clic, azioni compiute, durata delle sessioni, etc. </li>
          <li>Dati relativi alla posizione esatta dell’Interessato: ad esempio, dati di geolocalizzazione che identificano in modo preciso la posizione dell’Interessato che possono essere raccolti tramite la rete satellitare (es. GPS) e altri mezzi, raccolti previo consenso dell’Interessato. L’Interessato può revocare il consenso in qualsiasi momento. </li>
        </ul>
      </div>
      <div>
        Il mancato conferimento da parte dell’Interessato dei Dati Personali per i quali sussiste l’obbligo legale, contrattuale o qualora costituiscano requisito necessario per la conclusione del contratto con il Titolare, comporterà l’impossibilità del Titolare di instaurare o proseguire il rapporto con l’Interessato.
      </div>
      <div>
        L’Interessato che comunichi al Titolare Dati Personali di terzi è direttamente ed esclusivamente responsabile della loro provenienza, raccolta, trattamento, comunicazione o diffusione.

      </div>
      <h2>2. Cookie e tecnologie simili</h2>
      <div>
        L'Applicazione usa cookie, web beacon, identificatori univoci e altre analoghe tecnologie per raccogliere Dati Personali dell’Interessato sulle pagine, sui collegamenti visitati e sulle altre azioni che si eseguono quando l’Interessato utilizza l’Applicazione. Essi vengono memorizzati per essere poi trasmessi alla successiva visita dell'Interessato. Si può prendere visione della Cookie Policy completa al seguente indirizzo: https://www.easely.it/cookie-policy
      </div>
      <h2>3. Base giuridica e finalità del trattamento</h2>
      <div>Il trattamento dei Dati Personali è necessario:
        <div>
          a. per l'esecuzione del contratto con l’Interessato e precisamente:
          <ul>
            <li>
              adempimento di ogni obbligo derivante dal rapporto precontrattuale o contrattuale con l’Interessato
            </li>
            <li>
              registrazione e autenticazione dell’Interessato: per consentire all’Interessato di registrarsi sull’Applicazione, accedere e essere identificato anche tramite piattaforme esterne
            </li>
            <li>supporto e contatto con l’Interessato: per rispondere alle richieste dell’Interessato
            </li>
            <li>
              gestione dei pagamenti: per gestire i pagamenti tramite carta di credito, bonifico bancario o altri strumenti
            </li>
          </ul>
        </div>
        <div>
          b. per obbligo di legge e precisamente:
          <ul>
            <li>
              l’adempimento di qualunque obbligo previsto dalle vigenti normative, leggi e regolamenti, in particolare, in materia tributaria e fiscale
            </li>
          </ul>
        </div>
        <div>
          c. sulla base del legittimo interesse del Titolare, per:
          <ul>
            <li>
              finalità di marketing via email di prodotti e/o servizi del titolare per vendere direttamente i prodotti o servizi del Titolare utilizzando l’e-mail fornita dall’Interessato nel contesto della vendita di un prodotto o servizio analogo a quello oggetto della vendita
            </li>
            <li>
              gestione, ottimizzazione e monitoraggio dell’infrastruttura tecnica: per identificare e risolvere eventuali problemi tecnici, per migliorare le performance dell’Applicazione, per gestire e organizzare le informazioni in un sistema informatico (es. server, database, etc.)
            </li>
            <li>
              sicurezza e antifrode: per garantire la sicurezza del patrimonio, delle infrastrutture e delle reti del Titolare
            </li>
            <li>
              statistica con dati anonimi: per effettuare analisi statistiche su dati aggregati e anonimi per analizzare i comportamenti dell’Interessato, per migliorare i prodotti e/o i servizi forniti dal Titolare e meglio soddisfare le aspettative dell’Interessato
            </li>
          </ul>
        </div>
        <div>
          d. sulla base del consenso dell’Interessato, per:
          <ul>
            <li>
              profilazione dell’Interessato per fini di marketing: per fornire all’Interessato informazioni sui prodotti e/o servizi del Titolare attraverso un trattamento automatizzato finalizzato alla raccolta di informazioni personali con lo scopo di prevedere o valutare le sue preferenze o comportamenti
            </li>
            <li>
              comunicazione dei Dati Personali per finalità di marketing di terzi: per comunicare a terzi che operano nel settore assicurazioni, retail, energy&utilities, telco, banking i Dati Personali affinché li utilizzino per inviare loro informazioni o materiali commerciali e/o promozionali o per effettuare attività di vendita diretta dei loro prodotti e/o servizi o per compiere ricerche di mercato con modalità automatizzate e tradizionali
            </li>
            <li>
              rilevazione della posizione esatta dell’Interessato: per rilevare la presenza dell'Interessato, per controllare gli accessi, gli orari e la presenza dell'Interessato in un determinato luogo, etc.
            </li>
          </ul>
        </div>
        <div>
          Sulla base del legittimo interesse del Titolare, l’Applicazione permette di effettuare interazioni con piattaforme esterne o social network il cui trattamento dei Dati Personali è regolato dalle rispettive informative privacy alle quali si prega di fare riferimento. Le interazioni e le informazioni acquisite da questa Applicazione sono in ogni caso soggette alle impostazioni privacy che l’Interessato ha scelto su tali piattaforme o social network. Queste informazioni – in assenza di un specifico consenso al trattamento per ulteriori finalità – vengono utilizzate al solo fine di consentire la fruizione dell’Applicazione e fornire le informazioni e i servizi richiesti.
          <br /><br />I Dati Personali dell'Interessato possono inoltre essere utilizzati dal Titolare per tutelarsi in giudizio avanti le sedi giudiziarie competenti.
        </div>
      </div>
      <h2>4. Modalità di trattamento e destinatari dei Dati Personali</h2>
      <div>
        Il trattamento dei Dati Personali viene effettuato mediante strumenti cartacei e informatici con modalità organizzative e con
        logiche strettamente correlate alle finalità indicate e mediante l'adozione di adeguate misure di sicurezza. <br /><br />
        I Dati Personali sono trattati esclusivamente da:
        <ul>
          <li>persone autorizzate dal Titolare del trattamento dei Dati Personali che si sono impegnate alla riservatezza o abbiano un adeguato obbligo legale di riservatezza;</li>
          <li>
            soggetti che operano in autonomia come distinti titolari del trattamento o da soggetti designati quali responsabili del trattamento dal Titolare al fine di svolgere tutte le attività di trattamento necessarie a perseguire le finalità di cui alla presente informativa (ad esempio, partner commerciali, consulenti, società informatiche, fornitori di servizi, hosting provider);
          </li>
          <li>
            soggetti o enti a cui è obbligatorio comunicare i Dati Personali per obbligo di legge o per ordine delle autorità.</li>
        </ul>
        I soggetti sopra elencati sono tenuti a utilizzare le garanzie appropriate per proteggere i Dati Personali e possono accedere solo a quelli necessari per eseguire i compiti a loro assegnati.
        I Dati Personali non verranno diffusi indiscriminatamente in alcun modo.
      </div>
      <h2>5. Luogo</h2>
      <div>
        I Dati Personali non saranno oggetto di alcun trasferimento al di fuori del territorio dello Spazio Economico Europeo (SEE).
      </div>
      <h2>6. Periodo di conservazione dei Dati Personali</h2>
      <div>I Dati Personali saranno conservati per il periodo di tempo necessario ad adempiere alle finalità per i quali sono stati raccolti, in
        particolare:
        <ul>
          <li>per finalità relative all’esecuzione del contratto tra il Titolare e l’Interessato, saranno conservati per tutta la durata del rapporto contrattuale e, dopo la cessazione, per il periodo di prescrizione ordinario pari a 10 anni. Nel caso di contenzioso giudiziale, per tutta la durata dello stesso, fino all’esaurimento dei termini di esperibilità delle azioni di impugnazione</li>
          <li>per finalità relative al legittimo interesse del Titolare, saranno conservati fino al compimento di tale interesse</li>
          <li>per l’adempimento di un obbligo di legge, per ordine di un’autorità e per la tutela in giudizio, saranno conservati nel rispetto delle tempistiche previste da detti obblighi, normative e comunque sino al compimento del termine prescrizionale previsto dalle norme in vigore</li>
          <li>per finalità basate sul consenso dell’Interessato, saranno conservati sino alla revoca del consenso. Per finalità di marketing per un periodo non superiore a 24 mesi.
          </li>
        </ul>
        Al termine del periodo di conservazione, tutti i Dati Personali saranno cancellati o conservati in una forma che non consenta l’identificazione dell’Interessato.
      </div>
      <h2>7. Diritti dell’Interessato</h2>
      <div>
        Gli Interessati possono esercitare determinati diritti con riferimento ai Dati Personali trattati dal Titolare. In particolare, l’Interessato ha il diritto di:
        <ul>
          <li>essere informato sul trattamento dei propri Dati Personali</li>
          <li>revocare il consenso in ogni momento</li>
          <li>limitare il trattamento dei propri Dati Personali</li>
          <li>opporsi al trattamento dei propri Dati Personali</li>
          <li>accedere ai propri Dati Personali</li>
          <li>verificare e chiedere la rettifica dei propri Dati Personali</li>
          <li>ottenere la limitazione del trattamento dei propri Dati Personali</li>
          <li>ottenere la cancellazione dei propri Dati Personali</li>
          <li>trasferire i propri Dati Personali ad altro titolare</li>
          <li>proporre reclamo all’autorità di controllo della protezione dei propri Dati Personali e/o agire in sede giudiziale.</li>
        </ul>
        Per esercitare i propri diritti, gli Interessati possono indirizzare una richiesta al seguente indirizzo e-mail <a href="mailto:info@easely.it">info@easely.it</a>. Le richieste saranno prese in carico dal Titolare immediatamente ed evase nel più breve tempo possibile, in ogni caso entro 30 giorni.
      </div>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
