import React from 'react';
import { intlShape } from '../../../util/reactIntl';
import config from '../../../config';

import css from './HobbiesSelectionField.module.css';
import { Field } from 'react-final-form';
import { FieldMultiSelect } from '../../../components'

const HobbiesSelectionField = ({ intl }) => {
  const { hobbies } = config.custom;
  const hobbiesLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.hobbiesLabel',
  });
  const hobbiesPlaceholder = intl.formatMessage({
    id: 'ProfileSettingsForm.hobbiesPlaceholder',
  });

  return hobbies ? (
    <div>
      <label>{hobbiesLabel}</label>
      <Field
        component={FieldMultiSelect}
        name='hobbies'
        id='hobbies'
        options={hobbies}
        placeholder={hobbiesPlaceholder}
      />
    </div>
  ) : null;
};

HobbiesSelectionField.propTypes = {
  intl: intlShape.isRequired,
};

export default HobbiesSelectionField;
