import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';

import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { required, composeValidators } from '../../../util/validators';
import { FieldTextInput } from '../../../components';

import css from './DescriptionField.module.css';
import { FormattedMessage } from 'react-intl';


const DescriptionField = ({listingType, intl}) =>{
  const asterisk = (
    <span className={css.asterisk}>*</span>
  );

  // First name
  const descriptionMessage = (
    <FormattedMessage
      id='EditListingDescriptionForm.description'
      values={{asterisk}}
    />
  );
  const descriptionPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.descriptionPlaceholder'
  }, { listingType });

  const descriptionRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.descriptionRequired',
  });

  return (
    <FieldTextInput
      id="description"
      name="description"
      className={css.description}
      type="textarea"
      rows={5}
      label={descriptionMessage}
      placeholder={descriptionPlaceholderMessage}
      validate={composeValidators(required(descriptionRequiredMessage))}
    />
  )
}

export default DescriptionField
