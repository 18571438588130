import React from 'react';
import { bool } from 'prop-types';
import SelectListingTypeFilterPlain from './SelectListingTypeFilterPlain';
import SelectListingTypeFilterPopup from './SelectListingTypeFilterPopup';

const SelectListingTypeFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <SelectListingTypeFilterPopup {...rest} />
  ) : (
    <SelectListingTypeFilterPlain {...rest} />
  );
};

SelectListingTypeFilter.defaultProps = {
  showAsPopup: false,
};

SelectListingTypeFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectListingTypeFilter;
