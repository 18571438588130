import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import ScrollAnimation from 'react-animate-on-scroll';

import fondazioneLogo from './images/fondazione.png';
import foodForLifeLogo from './images/food-for-life.png';
import rainforestLogo from './images/rainforest.png';
import saveTheChildrenLogo from './images/save-children.png';
import waterLogo from './images/water.png';
import wormenForWomenLogo from './images/w4w.png';


import css from './SectionAssociations.module.css';

class EaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return (
      <div className={css.imageMargin}>
        <img alt={alt} {...rest} />
      </div>
    );
  }
}
const LazyImage = lazyLoadWithDimensions(EaselyImage);

const Image = ({src, title}) => (
  <div className={css.imageWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.easelyImage} />
    </div>
  </div>
);

const SectionAssociations = () => {
  return (
    <article className={css.root}>
      <h1 className={css.sectionAssociationsTitle}>
        <span className={css.highlightedByColor}>
          {" "}<FormattedMessage id="SectionAssociations.associationsTilteHead" />{" "}
        </span>
        <FormattedMessage id="SectionAssociations.associationsTilteTail" />
      </h1>
      <div className={css.associationsLogoWrapper}>
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={waterLogo} title='Water Logo' />
        </ScrollAnimation>
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={rainforestLogo} title='Rainforest Logo' />
        </ScrollAnimation>
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={saveTheChildrenLogo} title='Save the Children Logo' />
        </ScrollAnimation>
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={wormenForWomenLogo} title='Women for Women Logo' />
        </ScrollAnimation>
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={fondazioneLogo} title='Fondazione Logo' />
        </ScrollAnimation>
        <ScrollAnimation animateIn={css.lightSpeedIn}>
          <Image src={foodForLifeLogo} title='Food for Life Logo' />
        </ScrollAnimation>
      </div>
    </article>
  )
}

export default SectionAssociations;
