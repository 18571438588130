import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionContactUs.module.css';
import { ContactUsForm } from '../../forms';
import config from '../../config';
import { contactUs } from '../../util/api';


const SectionContactUs = ({intl}) => {
  const {contactEmail} = config;
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);

  const handleOnSubmit = async (values) => {
    setLoading(true);
    try {
      const res = await contactUs({...values, contactEmail});
      if(res.ok){
        setOk(true)
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <article className={css.root}>
      <h1 className={css.mobileTitle}>
        <FormattedMessage id="SustainabilityPage.SectionContactUsTitleHead" />
        <span className={css.higlightedByColor}>
          {" "}<FormattedMessage id="SustainabilityPage.SectionContactUsTitleMiddle" />{" "}
        </span>
        <FormattedMessage id="SustainabilityPage.SectionContactUsTitleTail" />
      </h1>
      <ContactUsForm onSubmit={handleOnSubmit} inProgress={loading} ok={ok} intl={intl} />
      <div className={css.titleWrapper}>
        <h1 className={css.title}>
          <FormattedMessage id="SustainabilityPage.SectionContactUsTitleHead" />
          <span className={css.higlightedByColor}>
            {" "}<FormattedMessage id="SustainabilityPage.SectionContactUsTitleMiddle" />{" "}
          </span>
          <FormattedMessage id="SustainabilityPage.SectionContactUsTitleTail" />
        </h1>
      </div>
    </article>
  )
}

export default SectionContactUs;
