import React, {Component} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import ScrollAnimation from 'react-animate-on-scroll';

import css from './SectionProjectsSupported.module.css';
import DonationProjectCards from './DonationProjectCards/DonationProjectCards';


class TryEaselyImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return (
      <div className={css.imageMargin}>
        <img alt={alt} {...rest} />
      </div>
    )
  }
}
const LazyImage = lazyLoadWithDimensions(TryEaselyImage);

const Image = ({src, title}) => (
  <div className={css.imageWrapper}>
    <div className={css.aspectWrapper}>
      <LazyImage src={src} alt={title} className={css.tryEaselyImage} />
    </div>
  </div>
);

const SectionProjectsSupported = () => {
  return (
    <article className={css.root}>
      <div>
        <h1 className={css.title}>
          <FormattedMessage id="SustainabilityPage.SectionProjectsSupportedTitleHead" />
          <span className={css.highlightedByColor}>
            {" "}<FormattedMessage id="SustainabilityPage.SectionProjectsSupportedTitleTail" />
          </span>
        </h1>
      </div>
      <DonationProjectCards />

    </article>
  )
}

export default SectionProjectsSupported;
